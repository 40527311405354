import { Backdrop, Box, CircularProgress, IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

const baseUrl = process.env.REACT_APP_BASH_URL;

function BookingDetails() {
    const options = { Authorization: `Bearer ${localStorage.getItem("admin-token")}`, "Content-Type": "multipart/form-data", role: "admin", };
    const { id } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [driver, setDriver] = useState("");
    const [booking, setBooking] = useState([]);

    const getAllBookingByDriverId = async () => {
        return await axios.get(`${baseUrl}/booking/get-booking-driver-id/${id}`).then((response) => {
            setBooking(response.data.result)
            // console.log("response: ", response.data.result);
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            console.log("error on getAllBookingByDriverId: ", error);
        })
    }

    useEffect(() => {
        getAllBookingByDriverId()
    }, [loading])
    return (
        <Box>
            <Box sx={{ display: "flex" }}>
                <IconButton onClick={() => navigate(-1)}>{" "}<KeyboardBackspaceIcon />{" "}</IconButton>
                <Typography variant="h5" component={"div"} fontWeight={700} sx={{ textDecoration: "underline", textUnderlineOffset: 3, marginLeft: 2, marginTop: 0.5, }}>Driver Details</Typography>
            </Box>

            {/* <CircularProgress /> */}
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Box component={Paper} sx={{}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Student Name</TableCell>
                            <TableCell align="center">Parent Name</TableCell>
                            <TableCell align="center">Parent Mobile No.</TableCell>
                            <TableCell align="center">PickUp point</TableCell>
                            <TableCell align="center">Role</TableCell>
                            <TableCell align="center">Payment Amount</TableCell>
                            <TableCell align="center">Payment Status</TableCell>
                            {/* <TableCell align="center">Booking Amount</TableCell> */}
                            <TableCell align="center">Driver Commission</TableCell>
                            <TableCell align="center">Parent Commission</TableCell>
                            <TableCell align="center">Booking Amount</TableCell>
                            <TableCell align="center">Rejected By Driver</TableCell>
                            <TableCell align="center">Rejected By Parent</TableCell>
                            <TableCell align="center">Booking Status</TableCell>
                            <TableCell align="center">Booking Date</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {booking.map((item, ind) => {
                            return (
                                <TableRow key={ind}>
                                    <TableCell align="center">{item?.student?.name}</TableCell>
                                    <TableCell align="center">{item?.parent?.name}</TableCell>
                                    <TableCell align="center">{item?.parent?.mobile}</TableCell>
                                    <TableCell align="center">{item?.student?.address}</TableCell>
                                    <TableCell align="center" sx={{ textTransform: 'capitalize' }}>{item?.role}</TableCell>
                                    <TableCell align="center">{item?.pay_amount}</TableCell>
                                    <TableCell align="center">{item?.payemnt_status ? "Paid" : "Not Paid"}</TableCell>
                                    <TableCell align="center">{item?.driver_commission_amount}</TableCell>
                                    <TableCell align="center">{item?.passenger_commission_amount}</TableCell>
                                    <TableCell align="center">{item?.amount}</TableCell>
                                    <TableCell align="center">{item?.is_reject_driver ? "Yes" : "No"}</TableCell>
                                    <TableCell align="center">{item?.is_reject_parent ? "Yes" : "No"}</TableCell>
                                    <TableCell align="center">{item?.active ? "Active" : "In-Active"}</TableCell>
                                    <TableCell align="center">{moment(item?.createdAt).format("LLL")}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Box>

        </Box>
    )
}

export default BookingDetails