import React from 'react';
import { Box, keyframes, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { FaCarCrash } from 'react-icons/fa';

const pingAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  75% {
    transform: scale(1.5);
    opacity: 0;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
`;

const activeText = "white"
const activeBg = "lightgray"

const PingBreakDown = ({ children }) => {
    return (
        <Box
            sx={{
                width: "100%", height: 48, borderRadius: 1, bgcolor: 'red', opacity: '0.5', position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center',
                '&::before': {
                    content: '""', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, borderRadius: 'inherit', backgroundColor: 'red', opacity: 0.5, zIndex: -1,
                },
                '&::after': {
                    content: '""', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, borderRadius: 'inherit', backgroundColor: 'red', animation: `${pingAnimation} 1s ease-in-out infinite`, zIndex: -1,
                },
            }}
        >
            {children}
        </Box>
    );
};

export default PingBreakDown;
