import { Box, Button, Dialog, DialogTitle, Divider, IconButton, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';


const basURL = process.env.REACT_APP_BASH_URL
function ReciveCash({ openDailog, setOpenDailog, admin, refresh, setRefresh, setSuccess, setError, setCustomVariant, setOpen, data, setData, loading, setLoading }) {
    const options = { 'Authorization': `Bearer ${localStorage.getItem('admin-token')}`, 'Content-Type': 'multipart/form-data', 'role': 'admin' }


    const [receive, setReceive] = useState()
    const [valReceive, setValReceive] = useState()

    const date = new Date();
    const dateOption = { timeZone: 'Asia/Kolkata' }; // Set the timezone to Indian Standard Time

    // Get the current date and time in Indian Standard Time
    const ISTDateString = date.toLocaleString('en-IN', dateOption);
    // console.log(ISTDateString);

    // Convert the date string to the desired format
    // const formattedDate = new Date(ISTDateString).toISOString();
    // console.log("new formate: ", formattedDate);

    const handleClose = () => {
        setOpenDailog(false)
        setReceive(0)
        setData("")
        setValReceive("")
    }

    const handleOnChange = (evt) => {
        setReceive(evt.target.value)
        setValReceive("")
    }

    // console.log("date: ", ISTDateString);

    const handlSubmit = async (evt) => {
        evt.preventDefault()
        if (!receive) {
            setValReceive("Receive amount is required!")
        } else {
            setLoading(true)
            return await axios.post(`${basURL}/payments/passegner-cash-payment`, { paymentId: data?.id, pay_amount: receive, collected_by: admin?.id, collected_at: ISTDateString, studentId: data?.userId }, { headers: options }).then((response) => {
                setError("")
                setSuccess(response.data.msg)
                setCustomVariant("success")
                setOpen(true)
                setRefresh(!refresh)
                setLoading(false)
                handleClose()
            }).catch((error) => {
                setSuccess("")
                setError(error.response.data.msg)
                setCustomVariant("error")
                setOpen(true)
                setLoading(false)
            })
        }
    }

    useEffect(() => {
        let amount = data?.payment_amount
        setReceive(amount)
    }, [data])

    // console.log("item: ", data);

    return (
        <Dialog open={openDailog} onClose={handleClose} scroll={'paper'} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
            <Box sx={{ display: 'flex', justifyContent: 'space-between', pr: 1 }}>
                <DialogTitle id="scroll-dialog-title">Receive Cash</DialogTitle>
                <IconButton sx={{ width: 40, height: 40 }} onClick={handleClose}><CloseIcon /></IconButton>
            </Box>
            <Divider />
            <Box component={'form'} noValidate sx={{ width: 400, m: 2 }} onSubmit={handlSubmit}>

                <TextField type='number' name='receive' id="outlined-basic" label="Receive Cash" placeholder='Enter receive cash!' variant="outlined" value={receive} onChange={handleOnChange} error={valReceive ? true : false} helperText={valReceive ? valReceive : ''} fullWidth sx={{ my: 1 }} autoFocus={true} />

                <Button variant='outlined' color='success' fullWidth sx={{ mt: 1 }} type='submit'> Submit </Button>
            </Box>
        </Dialog>
    )
}

export default ReciveCash