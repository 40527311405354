import { Avatar, Backdrop, Box, CircularProgress, Container, Divider, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { IoMdClose } from "react-icons/io";



const localUrl = process.env.REACT_APP_BASH_URL;


function StudentDetails() {
  const options = { Authorization: `Bearer ${localStorage.getItem("admin-token")}`, "Content-Type": "application/json", role: "admin", };
  const { id } = useParams();
  const navigate = useNavigate();

  const [student, setStudent] = useState('')
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [attendance, setAttendance] = useState([]);
  const [filterdData, setFilterData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [search, setSearch] = useState("");

  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)

  const getStudent = async () => {
    return await axios.get(`${localUrl}/students/get-by-id/${id}`, { headers: options }).then((response) => {
      setStudent(response.data.result);
      setLoading(false);
    }).catch((error) => {
      console.log("error on getStudent: ", error);
      setLoading(false);
      // setError(error.response.data.msg);
    });
  };

  const getAttendanceByStudentId = async () => {
    return await axios.get(`${localUrl}/attendance/student-attendance/${id}`, { headers: options, }).then((response) => {
      setAttendance(response.data.result);
      setFilterData(response.data.result)
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      console.log("error on getAttendanceByStudentId: ", error);
    });
  };


  // This is for Designing
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const clearDate = async () => {
    setFromDate(null)
    setToDate(null)
    setFilterData(attendance)
  }


  const filterDriversByDate = (startDate, toDate) => {
    if (!startDate || !toDate) {
      return attendance;
    }

    const startDateFormatted = moment(startDate.$d)
    const endDateFormatted = moment(toDate.$d)

    return attendance.filter(element => {
      let recordDate = moment(element.pickupAt);

      return recordDate.isBetween(startDateFormatted, endDateFormatted);
    });
  };

  // Call this function whenever the date range changes
  useEffect(() => {
    if (fromDate && toDate) {
      const filteredAttendance = filterDriversByDate(fromDate, toDate);
      setFilterData(filteredAttendance);
    }
  }, [fromDate, toDate, attendance]);


  useEffect(() => {
    getStudent();
    getAttendanceByStudentId();
  }, [loading]);

  return (
    <div >

      {/* <CircularProgress /> */}
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}><CircularProgress color="inherit" /></Backdrop>


      <Box sx={{ display: "flex" }}>
        <IconButton onClick={() => navigate(-1)}>{" "}<KeyboardBackspaceIcon />{" "}</IconButton>
        <Typography variant="h5" component={"div"} fontWeight={700} sx={{ textDecoration: "underline", textUnderlineOffset: 3, marginLeft: 2, marginTop: 0.5, }}>Attendance of Student {student?.name}</Typography>
      </Box>

      <Grid spacing={1} container sx={{ mt: 1, pr: 2 }}>
        <Grid item md={4} lg={3} sm={5} xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ width: '100%' }}>
            <DatePicker label="From Date" value={fromDate} onChange={(date) => setFromDate(date)} name="startDate" sx={{ width: '100%', mr: 1 }} />
          </LocalizationProvider>
        </Grid>
        <Grid item md={4} lg={3} sm={5} xs={11}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="To Date" value={toDate} onChange={(date) => setToDate(date)} name="endDate" sx={{ width: '100%', ml: 1 }} />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={1} md={2} lg={2} sm={2}>
          <IconButton onClick={clearDate} sx={{ width: '50px', height: '50px', alignSelf: 'center' }}> <IoMdClose size={30} /> </IconButton>
        </Grid>
      </Grid>

      <Box component={Paper} sx={{ marginTop: 1, mr: 2 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "bold", color: "#1976d2" }} align="center">Name</TableCell>
                <TableCell style={{ fontWeight: "bold", color: "#1976d2" }} align="center">Pickup At</TableCell>
                <TableCell style={{ fontWeight: "bold", color: "#1976d2" }} align="center">Drop At</TableCell>
                <TableCell style={{ fontWeight: "bold", color: "#1976d2" }} align="center">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filterdData && filterdData.length ? (filterdData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, ind) => {
                return (
                  <TableRow hover role="checkbox" sx={{ cursor: "pointer" }} key={ind}>
                    <TableCell align="center">{item.name}</TableCell>
                    <TableCell align="center">{item?.is_pickup ? moment(item.pickupAt).format("LLL") : ''}</TableCell>
                    <TableCell align="center">{item?.is_drop ? moment(item.dropAt).format("LLL") : ''}</TableCell>
                    <TableCell align="center" sx={{ color: item.is_drop || item?.is_pickup ? "green" : "red" }}>{item.is_drop || item?.is_pickup ? "Present" : "Absent"}</TableCell>
                  </TableRow>
                );
              })) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <Typography>No Student attendance found!</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination rowsPerPageOptions={[10, 25, 100]} component="div" count={filterdData.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
        </TableContainer>
      </Box>

    </div>
  );
}

export default StudentDetails;
