import { Box } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'

function ReferDetails() {
    const { id } = useParams()

    return (
        <Box>
            id: {id}
        </Box>
    )
}

export default ReferDetails