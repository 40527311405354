import { Box, Button, IconButton, TableCell, TableRow, Tooltip } from '@mui/material'
import moment from 'moment';
import React, { useEffect } from 'react'
import { useStopwatch } from 'react-timer-hook';

const imageUrl = process.env.REACT_APP_BASH_IMAGE_URL;

function BreakDownView({ item, handleApproveBreakDown, ind }) {

    const { totalSeconds, seconds, minutes, hours, days, isRunning, start, pause, reset, } = useStopwatch({});

    useEffect(() => {
        if (!item.status) {
            start()
        }
    }, [item])

    const duration = moment.duration(moment(item?.updatedAt).diff(moment(item?.createdAt)))

    return (
        <TableRow key={ind}>
            <TableCell sx={{ color: "black" }} component="th" scope="row">{item?.drivers?.name}</TableCell>
            {/* <TableCell sx={{ color: "black" }}>{item.description}</TableCell> */}
            <TableCell sx={{ color: "black" }}>{item?.address}</TableCell>
            <TableCell sx={{ color: "black" }} align="center">{item.type}</TableCell>
            <TableCell sx={{ color: "black" }} align="center"> <Box sx={{ width: '100%', height: 'auto' }}><img src={`${imageUrl}/images/breakDown/${item?.image}`} alt={``} style={{ resize: 'both', width: '200px', height: '100px' }} /></Box> </TableCell>
            <TableCell sx={{ color: "black" }} align="center">{moment(item.createdAt).format('LLL')}</TableCell>
            <TableCell sx={{ color: "black" }} align="center">
                {item?.status ? <Box>{Math.floor(duration.asHours())}:{duration.minutes()}:{duration.seconds()}</Box> : <Box>{hours}:{minutes}:{seconds}</Box>}
                {item.status ? <Tooltip title="Click To Breakdown"><Button variant="outlined" color="success" onClick={() => handleApproveBreakDown(item.id, false)} >Resolved</Button></Tooltip> : <Tooltip title="Click To Resolved"><Button variant="outlined" color="warning" onClick={() => handleApproveBreakDown(item.id, true)}>Breakdown</Button></Tooltip>}
            </TableCell>
        </TableRow>
    )
}

export default BreakDownView