import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { Link, resolvePath, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import axios from "axios";
import MuiAlert from "@mui/material/Alert";
import { Backdrop, CircularProgress, FormControl, IconButton, InputAdornment, InputLabel, MobileStepper, OutlinedInput, Paper, Snackbar, } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { generateToken } from "../notifications/firebase";
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import OtpInput from 'react-otp-input';

const theme = createTheme();

// Alert notification of MUI
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SignIn() {
    const navigate = useNavigate();
    // const url = "https://team-career-camp.onrender.com"
    const bashUrl = process.env.REACT_APP_BASH_URL;
    const [open, setOpen] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const [customVariant, setCustomVariant] = React.useState("success");
    const [error, setError] = React.useState("");
    const [success, setSuccess] = React.useState("")
    const [loading, setLoading] = React.useState(false)
    const [emp, setEmp] = React.useState({
        phone: "",
        password: "",
        fcm_token: "",
        role: "admin"
    });

    const [valErr, setValErr] = React.useState({
        phone: "",
        password: "",
    });

    // ============================================ otp ============================================
    const [otp, setOtp] = React.useState('');
    const [valOTP, setValOTP] = React.useState("");

    // handle close for UI component alert
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    let fcmTokenlocal = localStorage.getItem("fcm_token")

    React.useEffect(() => {
        generateToken()
        setEmp({ ...emp, fcm_token: fcmTokenlocal })
    }, [])

    // input onchange event
    const handleOnChange = (evt) => {
        setEmp({
            ...emp,
            [evt.target.name]: evt.target.value,
        });

        setValErr({
            phone: "",
            password: "",
        });
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    // =========================================== stepper ========================================
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = 3;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        console.log("handlBack");

        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        if (activeStep === 0) {
            console.log("call 0");
            setOtp("")
            setEmp({ ...emp, phone: '' })
        }
        if (activeStep === 1) {
            console.log("call 1");
            // setOpen("")
            setOtp("")
            setEmp({ ...emp, phone: '' })
        }
        if (activeStep === 2) {
            console.log("call 2");
            setOpen("")
        }
    };






    // handle submit for SigIn
    const handleSubmit = async () => {
        // evt.preventDefault();
        // console.log("clicked: ", bashUrl);
        if (!emp.phone) {
            setValErr({ phone: "Please enter mobile number!", });
        } else if (emp.phone.toString().length !== 10) {
            setValErr({ phone: "Please enter valid mobile number!", });
        } else if (!emp.password) {
            setValErr({ password: "Please enter password!", });
        } else if (emp.password.length < 5) {
            setValErr({ password: "Password must be more than 5 words!", });
        } else {
            setLoading(true)
            return await axios.post(`${bashUrl}/users/login`, emp).then((response) => {
                const token = response.data.token;
                localStorage.setItem("admin-token", token);
                // handleNext()
                navigate("/");
                setLoading(false)
            }).catch((err) => {
                setError(err?.response?.data.msg);
                setCustomVariant("error");
                setOpen(true);
                // console.log("err: ", err);
                setLoading(false)
            });
        }
    };


    const handleFunctionSteps = async (evt) => {
        evt.preventDefault();
        if (activeStep === 0) {
            // console.log("active step: 0", emp);

            if (!emp.phone) {
                setValErr({ phone: "Please enter mobile number!", });
            } else if (emp.phone.toString().length !== 10) {
                setValErr({ phone: "Please enter valid mobile number!", });
            } else {
                setLoading(true)
                return await axios.post(`${bashUrl}/users/send-otp-admin`, { phone: emp.phone }).then((response) => {
                    setError("")
                    setSuccess(response.data.msg)
                    setCustomVariant("success")
                    setOpen(true)
                    handleNext()
                    setLoading(false)
                }).catch((error) => {
                    setSuccess("")
                    setError(error.response.data.msg)
                    setCustomVariant("error")
                    setOpen(true)
                    setLoading(false)
                    console.log("error: ", error);
                })
            }
        } else if (activeStep === 1) {
            if (!otp) {
                setValOTP("OTP is required!")
            } else if (otp.length !== 4) {
                setValOTP("Invalid OTP!")
            } else {
                setLoading(true)
                return await axios.post(`${bashUrl}/users/verify-otp-admin`, { mobile: emp.phone, otp: otp }).then((response) => {
                    setError("")
                    setSuccess(response.data.msg)
                    setCustomVariant("success")
                    setOpen(true)
                    setLoading(false)
                    handleNext()
                }).catch((error) => {
                    setSuccess("")
                    setError(error.response.data.msg)
                    setCustomVariant("error")
                    setOpen(true)
                    setLoading(false)
                    // console.log("error: ", error);
                })
            }
        } else if (activeStep === 2) {
            // console.log("active step: 2", emp);
            setEmp({ ...emp, fcm_token: fcmTokenlocal, role: "admin" })
            handleSubmit()
        }
    }

    // <IoMdEyeOff />
    // console.log("emp: ", emp);


    return (
        <ThemeProvider theme={theme}>
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading} onClick={() => setLoading(false)}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "right" }} key={"top" + "right"}>
                <Alert onClose={handleClose} severity={customVariant} sx={{ width: "100%" }}>{error ? error : success}</Alert>
            </Snackbar>
            <Grid container component="main" maxWidth="xs" sx={{ marginTop: 20 }}>
                <CssBaseline />
                <Grid item xs={false} sm={3} md={3} />

                <Grid item xs={12} sm={6} md={6} component={Paper} elevation={6} square>
                    <Box component="form" noValidate>
                        <Box sx={{ bgcolor: "#3584ef", height: 100, margin: 5, marginTop: -4, borderRadius: 2, }}>
                            <Typography component="h1" variant="h5" sx={{ textAlign: "center", color: "#fff", paddingTop: 2 }}>Admin Login</Typography>
                            <Typography sx={{ textAlign: "center", color: "#fff" }}>Please Enter your Login Details..!!</Typography>
                            <Typography sx={{ textAlign: "center", color: "#fff" }}>{activeStep === 0 && "Verify Mobile Number"}{activeStep === 1 && "Verify OTP"}</Typography>
                        </Box>

                        <Box sx={{ marginTop: 0, display: "flex", flexDirection: "column", alignItems: "center", p: 2, marginBottom: 4, }}>
                            <Grid container spacing={2}>
                                {activeStep === 0 &&
                                    <Grid item xs={12}>
                                        <TextField error={valErr.phone ? true : false} helperText={valErr.phone ? valErr.phone : ""} required fullWidthid="phone" onChange={handleOnChange} label="Mobile Number" name="phone" autoComplete="phone" type={"number"} fullWidth />
                                    </Grid>
                                }

                                {activeStep === 1 && (
                                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", flexDirection: 'column' }}>
                                        <OtpInput value={otp} onChange={setOtp} numInputs={4} renderSeparator={<span>-</span>} renderInput={(props) => <input {...props} />}
                                            containerStyle={{ display: "flex", justifyContent: "center", width: "100%", }}
                                            inputStyle={{ width: "3rem", height: "3rem", margin: "0 0.5rem", fontSize: "2rem", borderRadius: 4, border: "1px solid rgba(0,0,0,0.3)", }}
                                        />
                                        <Typography variant="caption" color={'error'} sx={{ alignSelf: 'center' }}>{valOTP}</Typography>
                                    </Grid>
                                )}

                                {activeStep === 2 &&
                                    <Grid item xs={12} sx={{ mt: 1 }}>

                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                            <OutlinedInput name="password" value={emp.password} onChange={handleOnChange} id="outlined-adornment-password" type={showPassword ? "text" : "password"}
                                                endAdornment={
                                                    <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                }
                                                label="Password"
                                            />
                                        </FormControl>
                                    </Grid>
                                }
                            </Grid>
                        </Box>
                        {/* <Box sx={{ ml: 2 }}>
                            <Link to={`/password`} style={{ textDecoration: 'none' }}>Forgot password?</Link>
                        </Box> */}
                        <MobileStepper variant="text" steps={maxSteps} position="static" activeStep={activeStep}
                            nextButton={
                                <Button type="submit" size="small" variant="contained" onClick={handleFunctionSteps} disabled={activeStep === maxSteps}>{activeStep === 0 && "Send OTP"}{activeStep === 1 && "Verify OTP"}{activeStep === 2 && "Login"}{theme.direction === 'rtl' ? (<KeyboardArrowLeft />) : (<KeyboardArrowRight />)}</Button>
                                // <Button type="submit" fullWidth onClick={handleSubmit} variant="contained" sx={{ position: "relative", margin: "auto", padding: 2, width: 300, display: "block", bgcolor: "#3584ef", mb: 2, }}>Sign In</Button>
                            }
                            backButton={
                                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>{theme.direction === 'rtl' ? (<KeyboardArrowRight />) : (<KeyboardArrowLeft />)}Back</Button>
                            }
                        />

                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}
