import React, { useEffect, useState } from 'react'
import { Backdrop, Box, Button, CircularProgress, InputAdornment, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import SearchIcon from "@mui/icons-material/Search";
import moment from 'moment';
import MuiAlert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ModaleComponent from '../popup/ModaleComponent';
import BreakDownView from '../view/BreakDownView';


// Alert notification of MUI
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const localUrl = process.env.REACT_APP_BASH_URL;
const imageUrl = process.env.REACT_APP_BASH_IMAGE_URL;
function BreakDown() {
    const options = { Authorization: `Bearer ${localStorage.getItem("admin-token")}`, "Content-Type": "application/json", role: "admin", };
    const Navigate = useNavigate();

    

    const [booking, setBooking] = useState([])

    const [filterdData, setFilterData] = useState([]);

    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);

    const [search, setSearch] = useState("");

    const [data, setData] = useState({ name: "", id: "", role: "", });
    const [openModale, setOpenModale] = useState(false);


    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [open, setOpen] = useState(false);
    const [customVariant, setCustomVariant] = useState("success");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState();

    const getAllbooking = async () => {
        return await axios.get(`${localUrl}/break-down/get-all`).then((response) => {
            setBooking(response.data.result)
            setFilterData(response.data.result)
            setLoading(false)
        }).catch((error) => {
            console.log("error on getAllbooking: ", error);
            setLoading(false)
            if (error?.response?.status == 401) {
                localStorage.removeItem("admin-token")
            }
        })
    }


    // this is fro seraching data of driver
    useEffect(() => {
        const searchData = booking.filter((item) =>
            item?.drivers?.name.toLowerCase().includes(search.toLocaleLowerCase()) ? item : null
        );
        setFilterData(searchData);
    }, [search]);

    // This is for Designing
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        getAllbooking()
    }, [loading, refresh])


    const handleApproveBreakDown = async (id, progress) => {
        setData({ name: `Are you sure to update the status to ${progress ? "Resolved" : "Breakdown"}`, id: id, role: "breackDown", progress: progress })
        setOpenModale(true)
    }


    return (
        <Box>
            <Snackbar open={open} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "right" }} key={"top" + "right"} onClose={() => setOpen(false)}>
                <Alert onClose={() => setOpen(false)} severity={customVariant} sx={{ width: "100%" }}>{error ? error : success}</Alert>
            </Snackbar>

            <ModaleComponent openModale={openModale} setOpenModale={setOpenModale} data={data} setData={setData} setSuccess={setSuccess} setError={setError} setCustomVariant={setCustomVariant} refresh={refresh} setRefresh={setRefresh} setOpen={setOpen} />

            {/* <CircularProgress /> */}
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Box sx={{ width: "100%", pr: 2 }}>
                <Box sx={{ width: "100%", paddingY: 2 }}>
                    <Box sx={{ flexGrow: 1 }} />

                    <Box sx={{ width: "40%" }}>
                        <TextField fullWidth label="Search..." name="search" onChange={(evt) => setSearch(evt.target.value)} value={search} placeholder="Search Driver by name" id="outlined-start-adornment"
                            InputProps={{ startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>), }}
                        />
                    </Box>
                </Box>


                <TableContainer component={Paper}>
                    <Table >
                        <TableHead>
                            <TableRow>
                                <TableCell >Driver Name</TableCell>
                                {/* <TableCell >Description</TableCell> */}
                                <TableCell >Location</TableCell>
                                <TableCell align="center">Type</TableCell>
                                <TableCell align="center">Image</TableCell>
                                <TableCell align="center">Date At</TableCell>
                                <TableCell align="center"> Actions </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filterdData && filterdData.length ? (
                                filterdData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, ind) => {
                                    return (
                                        <BreakDownView item={item} ind={ind} handleApproveBreakDown={handleApproveBreakDown} />
                                    );
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={11} align="center">
                                        <Typography>No Driver data found!</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <TablePagination rowsPerPageOptions={[10, 25, 100]} component="div" count={filterdData.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
                </TableContainer>
            </Box>
        </Box>
    )
}

export default BreakDown