import { Backdrop, Box, Button, CircularProgress, Grid, IconButton, InputAdornment, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import MuiAlert from '@mui/material/Alert';
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment';
import '../view/styles.css'
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import ModaleComponent from '../popup/ModaleComponent';

// Alert notification of MUI
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const localUrl = process.env.REACT_APP_BASH_URL;
const imageUrl = process.env.REACT_APP_BASH_IMAGE_URL;


function Banner() {
    const options = { 'Authorization': `Bearer ${localStorage.getItem('admin-token')}`, 'Content-Type': 'multipart/form-data', 'role': 'admin' }

    const [banner, setBanner] = useState([])

    const [filterdData, setFilterData] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [refresh, setRefresh] = useState(false)
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(true)

    const [open, setOpen] = useState(false);
    const [customVariant, setCustomVariant] = useState('success')
    const [error, setError] = useState('')
    const [success, setSuccess] = useState()

    const [modaleOpen, setModaleOpen] = useState(false);

    const [data, setData] = useState({ parentId: "", id: "", role: "", name: "", });

    const [preView, setPreView] = useState('')

    const [addBanner, setAddBanner] = useState({
        name: '',
        image: '',
    })

    const [addBannerVal, setAddBannerVal] = useState({
        name: '',
        image: '',
    })


    const getAllBanner = async () => {
        return await axios.get(`${localUrl}/banners/get-all`).then((response) => {
            setBanner(response.data.result)
            setFilterData(response.data.result)
            // console.log("respon: ", response.data.result);
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            console.log("error: ", error);
            if (error?.response?.status == 401) {
                localStorage.removeItem("admin-token")
            }
        })
    }


    // this is fro seraching data of driver
    useEffect(() => {
        const searchData = banner.filter((item) => item?.name.toLowerCase().includes(search.toLocaleLowerCase()) ? item : null)
        setFilterData(searchData)
    }, [search])

    // This is for pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        getAllBanner()
    }, [loading, refresh])

    const handleImagChange = (evt) => {
        setAddBannerVal({ name: '', image: '' })
        setPreView(URL.createObjectURL(evt.target.files[0]));
        setAddBanner({ ...addBanner, image: evt.target.files[0] })
    }

    const handleSubmit = async (evt) => {
        evt.preventDefault()
        if (!addBanner.image) {
            setAddBannerVal({ ...addBannerVal, image: 'Image is required!' })
        } /* else if (!addBanner.name) {
            setAddBannerVal({ ...addBannerVal, name: 'Name is required!' })
        } */ else {
            setLoading(true)
            const formData = new FormData()
            formData.append("image", addBanner.image)
            formData.append("name", addBanner.name)
            return await axios.post(`${localUrl}/banners/add`, formData, { headers: options }).then((response) => {
                setError("")
                setSuccess(response.data.msg)
                setCustomVariant("success")
                setOpen(true)
                setAddBanner({ name: '', image: '' })
                setPreView("")
                setRefresh(!refresh)
                setLoading(false)
            }).catch((err) => {
                setSuccess("")
                setError(err.response.data.msg)
                setCustomVariant("error")
                setOpen(true)
                setLoading(false)
                if (err?.response?.status == 401) {
                    localStorage.removeItem("admin-token")
                }
            })
            
            // console.log("banner: ", addBanner);
        }
    }

    return (
        <Box>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading} onClick={() => setLoading(false)} >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)} anchorOrigin={{ vertical: "top", horizontal: "right" }} key={"top" + "right"}>
                <Alert onClose={() => setOpen(false)} severity={customVariant} sx={{ width: '100%' }}>
                    {error ? error : success}
                </Alert>
            </Snackbar>

            <ModaleComponent openModale={modaleOpen} setOpenModale={setModaleOpen} data={data} setData={setData} setCustomVariant={setCustomVariant} setOpen={setOpen} setSuccess={setSuccess} setError={setError} refresh={refresh} setRefresh={setRefresh} />

            <Box component={'form'} noValidate onSubmit={handleSubmit}>
                <Typography variant='h5' component={'div'} sx={{ fontWeight: 700 }}>Add Banner</Typography>
                <Box sx={{ width: '100%', height: 110, display: 'flex', alignItems: 'center', justifyContent: 'center', py: 1, mb: 1 }} component={Paper}>
                    <Grid spacing={2} container>
                        <Grid item xs={8}>
                            <Box sx={{ width: '100%', height: 90, display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                                <label htmlFor='image' >
                                    <img src={preView} alt={'Select banner image'} className='bannerImage' />
                                </label>
                                <input type='file' id='image' onChange={handleImagChange} style={{ display: 'none' }} />
                            </Box>
                            <Typography style={{ textAlign: "center" }} variant='subtitle2' color={'error'}  >{addBannerVal.image ? addBannerVal.image : ''}</Typography>
                        </Grid>
                        {/* <Grid item xs={4}>
                            <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <TextField type="text" name="name" id="outlined-basic" label="Name" variant="outlined" error={addBannerVal.name ? true : false} helperText={addBannerVal.name ? addBannerVal.name : ""} value={addBanner.name} onChange={(e) => { setAddBanner({ ...addBanner, name: e.target.value }); setAddBannerVal({ name: '', image: '' }) }} fullWidth required />
                            </Box>
                        </Grid> */}
                        <Grid item xs={4}>
                            <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                                <Button variant='contained' sx={{ height: 50, width: '50%' }} type='submit'>Submit</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            {/* <Box sx={{ width: '100%', paddingY: 2 }}>
                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ width: '40%' }}>
                    <TextField fullWidth label="Search..." name='serach' onChange={(e) => setSearch(e.target.value)} value={search} placeholder='Search Teacher by name' id="outlined-start-adornment" InputProps={{
                        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                    }} />
                </Box>
            </Box> */}
            <Typography variant='h5' component={'div'} sx={{ fontWeight: 700 }}>Banner List</Typography>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 350 }} aria-labelledby="tableTitle" >
                    <TableHead>
                        <TableRow>
                            <TableCell align='center'>Banner Image</TableCell>
                            {/* <TableCell align='center'>Name</TableCell> */}
                            <TableCell align='center'>Date Created</TableCell>
                            <TableCell align='center'>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filterdData && filterdData.length ? filterdData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, ind) => {
                            return (
                                <TableRow hover role="checkbox" sx={{ cursor: 'pointer' }} key={ind}>
                                    <TableCell component="th" scope="row" padding="none" align="center" > <img src={`${imageUrl}/images/banner/${item?.image}`} alt='Banner Image' style={{ width: '40%', height: 100 }} /> </TableCell>
                                    {/* <TableCell component="th" scope="row" padding="none" align="center" > {item?.name} </TableCell> */}
                                    <TableCell align="center">{moment(item?.createdAt).format('LL')}</TableCell>
                                    <TableCell align='center' >
                                        <Tooltip title="Delete School">
                                            <IconButton color="error" onClick={() => { setModaleOpen(true); setData({ ...data, parentId: item.id, role: "banner", name: item?.name, }); }}>{" "}<DeleteForeverOutlinedIcon />{" "}</IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>)
                        }) : <TableRow>
                            <TableCell colSpan={6} align='center'><Typography >No Banner data found!</Typography></TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
                <TablePagination rowsPerPageOptions={[10, 25, 100]} component="div" count={banner?.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
            </TableContainer>
        </Box>
    )
}

export default Banner