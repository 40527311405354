import { Box, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react'
import PingBreakDown from '../animation/PingBreakDown';


function BreakDownlList({ breakDown }) {

    return (
        <TableContainer component={Paper}>
            <Typography variant="h6" component="div" sx={{ p: 2, fontWeight: 'bold', bgcolor: 'rgb(25, 118, 210)', color: 'white' }}>Break Down</Typography>



            {/* <Divider /> */}
            <Table >
                <TableHead>
                    <TableRow sx={{ bgcolor: 'lightgrey', color: 'black' }}>
                        <TableCell sx={{ color: 'black' }} >Driver Name</TableCell>
                        <TableCell sx={{ color: 'black' }} >Description</TableCell>
                        <TableCell sx={{ color: 'black' }} >Location</TableCell>
                        <TableCell sx={{ color: 'black' }} align="center">Type</TableCell>
                        <TableCell align="center">Status</TableCell>
                        <TableCell sx={{ color: 'black' }} align="center">Created At</TableCell>
                        {/* <TableCell align="center"> Actions </TableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {breakDown ? breakDown?.map((item, ind) => {
                        return (
                            <TableRow key={ind}>
                                <TableCell sx={{ color: "black" }} component="th" scope="row">{item?.drivers?.name}</TableCell>
                                <TableCell sx={{ color: "black" }}>{item.description}</TableCell>
                                <TableCell sx={{ color: "black" }}>{item?.address}</TableCell>
                                <TableCell sx={{ color: "black" }} align="center">{item.type}</TableCell>
                                {item?.status ? <TableCell sx={{ color: "black" }} align="center">{item?.status ? "Resolved" : "Breakdown"}</TableCell> : <PingBreakDown ><TableCell sx={{ color: "white" }} align="center">{item?.status ? "Resolved" : "Breakdown"}</TableCell></PingBreakDown>}
                                {/* <TableCell sx={{ color: "black" }} align="center"> <Box sx={{ width: '100%', height: 'auto' }}><img src={`${imageUrl}/images/breakDown/${item?.image}`} alt={``} style={{ resize: 'both', width: '200px', height: '100px' }} /></Box> </TableCell> */}
                                {/* <TableCell sx={{ color: "black" }} align="center"> <Box sx={{ width: '100%', height: 'auto' }}><img src={`${imageUrl}/images/breakDown/${item?.image}`} alt={``} style={{ resize: 'both', width: '200px', height: '100px' }} /></Box> </TableCell> */}
                                <TableCell sx={{ color: "black" }} align="center">{moment(item.createdAt).format('LLL')}</TableCell>
                            </TableRow>
                        );
                    })
                        :
                        <TableRow>
                            <TableCell colSpan={10} align="center">
                                <Typography>No Driver data found!</Typography>
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default BreakDownlList