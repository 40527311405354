import { Backdrop, Box, Button, CircularProgress, IconButton, InputAdornment, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MuiAlert from '@mui/material/Alert';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchIcon from "@mui/icons-material/Search";
import moment from 'moment';
import BookingConfirm from '../popup/BookingConfirm';


// Alert notification of MUI
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const localUrl = process.env.REACT_APP_BASH_URL;
const imageUrl = process.env.REACT_APP_BASH_IMAGE_URL;
function Booking({ admin }) {
    const options = { Authorization: `Bearer ${localStorage.getItem("admin-token")}`, "Content-Type": "application/json", role: "admin", };
    const Navigate = useNavigate();

    const [booking, setBooking] = useState([])

    const [openDailog, setOpenDailog] = useState(false)


    const [filterdData, setFilterData] = useState([]);

    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(true);

    const [search, setSearch] = useState("");

    const [openModale, setOpenModale] = useState(false);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [data, setData] = useState({ name: "", id: "", active: "", });

    const [open, setOpen] = useState(false);
    const [customVariant, setCustomVariant] = useState("success");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState();

    const getAllbooking = async () => {
        return await axios.get(`${localUrl}/booking/get-all`).then((response) => {
            // console.log("response: ", response.data.result);
            setBooking(response.data.result)
            setFilterData(response.data.result)
            setLoading(false)
        }).catch((error) => {
            console.log("error on getAllbooking: ", error);
            setLoading(false)
            if (error?.response?.status == 401) {
                localStorage.removeItem("admin-token")
            }
        })
    }


    // this is fro seraching data of driver
    useEffect(() => {
        /* const searchData = booking.filter((item) =>
            item.name.toLowerCase().includes(search.toLocaleLowerCase()) ? item : null
        );
        setFilterData(searchData); */
    }, [search]);

    // This is for Designing
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        getAllbooking()
    }, [loading, refresh])


    return (
        <Box>
            <Snackbar open={open} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "right" }} key={"top" + "right"} onClose={() => setOpen(false)}>
                <Alert onClose={() => setOpen(false)} severity={customVariant} sx={{ width: "100%" }}>
                    {error ? error : success}
                </Alert>
            </Snackbar>

            {/* <CircularProgress /> */}
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* booking status change popup */}
            <BookingConfirm openDailog={openDailog} setOpenDailog={setOpenDailog} loading={loading} setLoading={setLoading} setSuccess={setSuccess} setError={setError} setCustomVariant={setCustomVariant} setOpen={setOpen} data={data} setData={setData} refresh={refresh} setRefresh={setRefresh} />

            <Box sx={{ width: "100%", pr: 2 }}>
                <Box sx={{ width: "100%", paddingY: 2 }}>
                    <Box sx={{ flexGrow: 1 }} />

                    <Box sx={{ width: "40%" }}>
                        <TextField fullWidth label="Search..." name="search" onChange={(evt) => setSearch(evt.target.value)} value={search} placeholder="Search Driver by name" id="outlined-start-adornment"
                            InputProps={{ startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>), }}
                        />
                    </Box>
                </Box>

                <TableContainer component={Paper}>
                    <Table >
                        <TableHead>
                            <TableRow>
                                <TableCell >Parent Name</TableCell>
                                <TableCell >Student Name</TableCell>
                                <TableCell >Driver Name</TableCell>
                                <TableCell align="center">Booking Amount</TableCell>
                                <TableCell align="center">Reject By Parent</TableCell>
                                <TableCell align="center">Reject By Driver</TableCell>
                                <TableCell align="center">Booking Date</TableCell>
                                <TableCell align="center">Booking Status</TableCell>
                                <TableCell align="center">Payment Status</TableCell>
                                <TableCell align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filterdData && filterdData.length ? (
                                filterdData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, ind) => {
                                    return (
                                        <TableRow key={ind}>
                                            <TableCell sx={{ color: "black" }} component="th" scope="row">{item?.parent?.name}</TableCell>
                                            <TableCell sx={{ color: "black" }} component="th" scope="row">{item?.student?.name}</TableCell>
                                            <TableCell sx={{ color: "black" }} component="th" scope="row">{item?.driver?.name}</TableCell>
                                            <TableCell sx={{ color: "black" }} align="center">{item.amount}{" "}</TableCell>
                                            <TableCell sx={{ color: "black" }} align="center">{item.is_reject_parent ? "Yes" : "No"}</TableCell>
                                            <TableCell sx={{ color: "black" }} align="center">{item.is_reject_driver ? "Yes" : "No"}</TableCell>
                                            <TableCell sx={{ color: "black" }} align="center">{moment(item.createdAt).format('LL')}</TableCell>
                                            {/* <TableCell sx={{ color: "black", textTransform: 'capitalize' }} align="center">{item.status == "rejected" && "Rejected"}{item.status == "approved" && "Approved"}{item.status == "pending" && "Pending"}{item.status == "active" && "Active"}</TableCell> */}
                                            <TableCell sx={{ color: "black", textTransform: 'capitalize' }} align="center">{item.status}</TableCell>
                                            <TableCell sx={{ color: "black" }} align="center">{item?.payemnt_status ? "Paid" : "Not Paid"}</TableCell>
                                            <TableCell sx={{ color: "black" }} align="center">
                                                {item?.student && <Tooltip title="View Student Details Booking"><Link to={`/student/booking/${item?.student?.id}`} style={{ textDecoration: "none" }}><IconButton color="success"><VisibilityIcon /></IconButton>{" "}</Link>{" "}</Tooltip>}
                                                {item?.active ? <Tooltip title="Click to de-active"><Button variant='contained' color='warning' onClick={() => { setOpenDailog(true); setData({ id: item?.id, active: false, approve: 'pending' }) }}>De-Active</Button></Tooltip> : <Tooltip title="Click to active"><Button variant='contained' color='success' onClick={() => { setOpenDailog(true); setData({ id: item?.id, active: true, approve: 'active' }) }}>Active</Button></Tooltip>}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={10} align="center">
                                        <Typography>No Booking data found!</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <TablePagination rowsPerPageOptions={[10, 25, 100]} component="div" count={filterdData.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
                </TableContainer>
            </Box>
        </Box>
    )
}

export default Booking