import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FaCarCrash } from "react-icons/fa";
import WaveAnimation from '../animation/WaveAnimation';
import WaveErrorAnimation from '../animation/WaveErrorAnimation';
import PingBox from '../animation/PingBox';


const activeText = "rgb(25, 118, 210)"
const activeBg = "lightgray"

function MenuListAlert({ menu, open, showError, setShowError }) {

    // const [showError, setshowError] = useState(false)


    return (
        <ListItem disablePadding sx={{ display: 'block', backgroundColor: menu.active === 16 ? activeBg : "", color: menu.active === 16 ? activeText : "black" }} onClick={() => setShowError(false)}>
            {showError ? <PingBox menu={menu} open={open} />
                :
                <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', }}>
                        <FaCarCrash size={24} color={menu.active === 16 ? activeText : ""} />
                    </ListItemIcon>
                    <ListItemText primary={"Break Down"} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
            }
        </ListItem>
    )
}

export default MenuListAlert