import React, { useEffect, useState } from 'react'
import { Avatar, Backdrop, Box, CircularProgress, InputAdornment, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material'
import MuiAlert from '@mui/material/Alert';
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment';
import axios from 'axios';

const basURL = process.env.REACT_APP_BASH_URL
const textColor = "#3684f0"
const imageUrl = process.env.REACT_APP_BASH_IMAGE_URL
// Alert notification of MUI
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function WithdrawalRequest() {

    const options = { 'Authorization': `Bearer ${localStorage.getItem('admin-token')}`, 'Content-Type': 'multipart/form-data', 'role': 'admin' }
    const [leaves, setLeaves] = useState([])
    const [filterdData, setFilterData] = useState([])

    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [open, setOpen] = useState(false);
    const [customVariant, setCustomVariant] = useState('success')
    const [error, setError] = useState('')
    const [success, setSuccess] = useState()
    const [search, setSearch] = useState('')

    const getAllLeaves = async () => {
        return await axios.get(`${basURL}/withdraw/get-all`, { headers: options }).then((response) => {
            // console.log("response: ", response.data.result);
            setFilterData(response.data.result)
            setLeaves(response.data.result)
            setLoading(false)
        }).catch((err) => {
            setSuccess("")
            setCustomVariant('error')
            setError(err.response.data.msg)
            setOpen(true)
            setLoading(false)
            if (err?.response?.status == 401) {
                localStorage.removeItem("admin-token")
            }
        })
    }

    // this is fro seraching data of driver
    useEffect(() => {
        const searchData = leaves.filter((item) => item.user.name.toLowerCase().includes(search.toLocaleLowerCase()) ? item : null)
        setFilterData(searchData)
    }, [search])

    // This is for pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        getAllLeaves()
    }, [loading])


    return (
        <Box sx={{ width: '100%', mt: 2 }} >
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading} onClick={() => setLoading(false)} >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
                <Alert onClose={() => setOpen(false)} severity={customVariant} sx={{ width: '100%' }}>
                    {error ? error : success}
                </Alert>
            </Snackbar>

            <Box sx={{ width: '100%', pb: 2 }}>
                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ width: '40%' }}>
                    <TextField fullWidth label="Search..." name='serach' onChange={(e) => setSearch(e.target.value)} value={search} placeholder='Search by name' id="outlined-start-adornment" InputProps={{
                        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                    }} />
                </Box>
            </Box>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" >
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ color: "#1976d2" }} align='center'>Driver Name</TableCell>
                            <TableCell style={{ color: "#1976d2" }} align='center'>Mobile Number</TableCell>
                            <TableCell style={{ color: "#1976d2" }} align='center'>reason</TableCell>
                            <TableCell style={{ color: "#1976d2" }} align='center'>Requeted Amount</TableCell>
                            <TableCell style={{ color: "#1976d2" }} align='center'>Status</TableCell>
                            <TableCell style={{ color: "#1976d2" }} align='center'>Date</TableCell>
                            {/* <TableCell style={{ color: "#1976d2" }} align='center'>Actions</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filterdData && filterdData.length ? filterdData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, ind) => {
                            return (
                                <TableRow hover role="checkbox" sx={{ cursor: 'pointer' }} key={ind}>
                                    <TableCell sx={{ color: textColor }} align='center' > {item?.user?.name} </TableCell>
                                    <TableCell sx={{ color: textColor }} align='center' > {item?.user?.mobile} </TableCell>
                                    <TableCell sx={{ color: textColor }} align='center'>{item?.reson}</TableCell>
                                    <TableCell sx={{ color: textColor }} align='center'>{item?.amount}</TableCell>
                                    <TableCell sx={{ color: textColor, textTransform: 'capitalize' }} align='center'>{item?.status}</TableCell>
                                    <TableCell sx={{ color: textColor }} align='center'>{moment(item.createdAt).format('LL')}</TableCell>
                                    {/* <TableCell sx={{ color: textColor }} align='center'>{item.resone}</TableCell> */}
                                </TableRow>)
                        }) : <TableRow>
                            <TableCell colSpan={6} align='center'><Typography >No withdraw request data found!</Typography></TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
                <TablePagination rowsPerPageOptions={[10, 25, 100]} component="div" count={filterdData.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
            </TableContainer>
        </Box>
    )
}

export default WithdrawalRequest