import { Avatar, Backdrop, Box, CircularProgress, Divider, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import MuiAlert from "@mui/material/Alert";
import moment from 'moment';
import BookingViewRouteDetails from '../comman/BookingViewRouteDetails';

const bashUrl = process.env.REACT_APP_BASH_URL;
const imageUrl = process.env.REACT_APP_BASH_IMAGE_URL;


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function StudentBooking() {
    const options = { Authorization: `Bearer ${localStorage.getItem("admin-token")}`, "Content-Type": "application/json", role: "admin", };
    const { id } = useParams()
    const navigate = useNavigate()

    const [student, setStudent] = useState('')
    const [booking, setBooking] = useState()
    const [vehicle, setVehicle] = useState('')

    const [school, setSchool] = useState('')

    const [loading, setLoading] = useState(true)

    const getStudentDetails = async () => {
        return await axios.get(`${bashUrl}/students/get-by-id/${id}`, { headers: options }).then((response) => {
            // console.log("response: ", response.data);

            setSchool(response.data.school)
            setStudent(response.data.result)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            console.log("error on getStudentDetails: ", error);
        })
    }

    const getAllBookingByStudentId = async () => {
        return await axios.get(`${bashUrl}/booking/get-by-student-id/${id}`).then((response) => {
            setBooking(response.data.result)
            setVehicle(response.data.vehicle)
            setLoading(false)
        }).catch((error) => {
            console.log("error on getAllBookingByStudentId: ", error);
            setLoading(false)
        })
    }

    useEffect(() => {
        getStudentDetails()
        getAllBookingByStudentId()
    }, [loading])

    // console.log("student: ", student);


    return (
        <div>
            <Box sx={{ display: "flex", mb: 1 }}>
                <IconButton onClick={() => navigate(-1)}>
                    <KeyboardBackspaceIcon />
                </IconButton>

                <Typography variant="h5" component={"div"} fontWeight={700} sx={{ textDecoration: "underline", textUnderlineOffset: 3, marginLeft: 2, marginTop: 0.5, }}>Student Details</Typography>
            </Box>

            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}><CircularProgress color="inherit" /></Backdrop>

            <Paper elevation={3} sx={{ width: '50%', mb: 1, p: 1, pb: 2 }}>
                <Box sx={{ width: '100%', display: 'flex' }}>
                    {/* <Box sx={{ justifyContent: "center", width: '50%' }}>
                        <Typography sx={{ mt: 1, ml: 1 }}>Profile Photo</Typography>
                        <Avatar src={`${imageUrl}/images/profile/${student?.photo}`} sx={{ width: 90, height: 90, ml: 1 }} alt={student?.name} />
                    </Box> */}

                    <Box sx={{ padding: "10px", width: '50%' }}>
                        <Typography>Name: {student?.name}</Typography>
                        {student.mobile && <Typography>Mobile No.: {student?.mobile}</Typography>}
                        <Typography>Father's Name: {student?.fatherName}</Typography>
                        <Typography>Address: {student?.address}</Typography>
                        <Typography>Area: {student?.area}</Typography>
                    </Box>
                </Box>
            </Paper>

            <TableContainer component={Paper}>
                <Typography sx={{ my: 2, ml: 2 }}>Booking Details</Typography>
                <Divider />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Driver Name</TableCell>
                            <TableCell align="center">Mobile No.</TableCell>
                            <TableCell align="center">Area</TableCell>
                            <TableCell align="center">Vehicle No.</TableCell>
                            <TableCell align="center">Vehicle Type</TableCell>
                            <TableCell align="center">School Name</TableCell>
                            <TableCell align="center">Booking Amount</TableCell>
                            <TableCell align="center">Payment Status</TableCell>
                            <TableCell align="center">Booking Status</TableCell>
                            <TableCell align="center">Booking Date</TableCell>
                            {/* <TableCell align="center"> School Name </TableCell>
                            <TableCell align="center"> Register Date </TableCell>
                            <TableCell align="center"> Actions </TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {booking ? <TableRow >
                            <TableCell>{booking?.driver?.name}</TableCell>
                            <TableCell align="center">{booking?.driver?.mobile}</TableCell>
                            <TableCell align="center">{student?.area}</TableCell>
                            <TableCell align="center">{vehicle?.vehicleNumber}</TableCell>
                            <TableCell align="center">{vehicle?.vehicleType}</TableCell>
                            <TableCell align="center">{school?.name}</TableCell>
                            <TableCell align="center">{booking?.pay_amount}</TableCell>
                            <TableCell align="center">{booking?.payemnt_status ? "Paid" : "Not Paid"}</TableCell>
                            <TableCell align="center">{booking?.active ? "Active" : "In-Active"}</TableCell>
                            {/* <TableCell align="center">Booking Date</TableCell> */}
                            <TableCell sx={{ color: "black" }} align="center">{moment(booking?.pay_date).format("LLL")}</TableCell>
                        </TableRow>
                            :
                            <TableRow>
                                <TableCell colSpan={9} align="center">
                                    <Typography>No booking data found!</Typography>
                                </TableCell>
                            </TableRow>
                        }

                    </TableBody>
                </Table>
            </TableContainer>

            <BookingViewRouteDetails student={student} school={school} />
        </div>
    )
}

export default StudentBooking