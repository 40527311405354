import { Avatar, Backdrop, Box, CircularProgress, InputAdornment, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import MuiAlert from '@mui/material/Alert';
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment';

const basURL = process.env.REACT_APP_BASH_URL
const textColor = "#3684f0"
const imageUrl = process.env.REACT_APP_BASH_IMAGE_URL
// Alert notification of MUI
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Leave() {
    const options = { 'Authorization': `Bearer ${localStorage.getItem('admin-token')}`, 'Content-Type': 'multipart/form-data', 'role': 'admin' }
    const [leaves, setLeaves] = useState([])
    const [filterdData, setFilterData] = useState([])

    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [open, setOpen] = useState(false);
    const [customVariant, setCustomVariant] = useState('success')
    const [error, setError] = useState('')
    const [success, setSuccess] = useState()
    const [search, setSearch] = useState('')

    const getAllLeaves = async () => {
        return await axios.get(`${basURL}/leaves/get-all-leave-student`, { headers: options }).then((response) => {
            console.log("response: ", response.data.result);
            setFilterData(response.data.result)
            setLeaves(response.data.result)
            setLoading(false)
        }).catch((err) => {
            setSuccess("")
            setCustomVariant('error')
            setError(err.response.data.msg)
            setOpen(true)
            setLoading(false)
            if (err?.response?.status == 401) {
                localStorage.removeItem("admin-token")
            }
        })
    }

    // this is fro seraching data of driver
    useEffect(() => {
        const searchData = leaves.filter((item) => item.sName.toLowerCase().includes(search.toLocaleLowerCase()) ? item : null)
        setFilterData(searchData)
    }, [search])

    // This is for pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        getAllLeaves()
    }, [loading])

    return (
        <div >
            <Box sx={{ width: '100%' }} >
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading} onClick={() => setLoading(false)} >
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
                    <Alert onClose={() => setOpen(false)} severity={customVariant} sx={{ width: '100%' }}>
                        {error ? error : success}
                    </Alert>
                </Snackbar>

                <Box sx={{ width: '100%', mb: 2, mt: 2 }}>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ width: '40%' }}>
                        <TextField fullWidth label="Search..." name='serach' onChange={(e) => setSearch(e.target.value)} value={search} placeholder='Search by name' id="outlined-start-adornment" InputProps={{
                            startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                        }} />
                    </Box>
                </Box>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" >
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ color: "#1976d2" }} align='left'>Profile</TableCell>
                                <TableCell style={{ color: "#1976d2" }} align='left'>Name</TableCell>
                                <TableCell style={{ color: "#1976d2" }} align='left'>Phone</TableCell>
                                <TableCell style={{ color: "#1976d2" }} align='left'>From</TableCell>
                                <TableCell style={{ color: "#1976d2" }} align='left'>To</TableCell>
                                <TableCell style={{ color: "#1976d2" }} align='left'>Type</TableCell>
                                <TableCell style={{ color: "#1976d2" }} align='center'>reason</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filterdData && filterdData.length ? filterdData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, ind) => {
                                return (
                                    <TableRow hover role="checkbox" sx={{ cursor: 'pointer' }} key={ind}>
                                        <TableCell sx={{ color: textColor }} align="right"><Avatar sx={{}} alt={item?.sName} src={`${imageUrl}/images/profile/` + item?.sPhoto} /> </TableCell>
                                        <TableCell sx={{ color: textColor }} component="th" scope="row" padding="none" align='left' > {item.sName} </TableCell>
                                        <TableCell sx={{ color: textColor }} align='left'>{item.phone}</TableCell>
                                        <TableCell sx={{ color: textColor }} align='left'>{moment(item.startDate).format('LL')}</TableCell>
                                        <TableCell sx={{ color: textColor }} align='left'>{moment(item.endDate).format('LL')}</TableCell>
                                        <TableCell sx={{ color: textColor, textTransform: 'capitalize' }} align='left'>{item?.roles}</TableCell>
                                        <TableCell sx={{ color: textColor }} align='center'>{item.resone}</TableCell>
                                    </TableRow>)
                            }) : <TableRow>
                                <TableCell colSpan={7} align='center'><Typography >No Leave data found!</Typography></TableCell>
                            </TableRow>}
                        </TableBody>
                    </Table>
                    <TablePagination rowsPerPageOptions={[10, 25, 100]} component="div" count={filterdData.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
                </TableContainer>
            </Box>
        </div>
    )
}

export default Leave