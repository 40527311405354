import logo from './logo.svg';
import './App.css';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';
import Dashboard from './pages/Dashboard';
import SignIn from './pages/SignIn';
import { jwtDecode } from "jwt-decode";
import { generateToken } from './notifications/firebase';
import ResetPassword from './pages/ResetPassword';

function App() {
  const navigate = useNavigate();
  const token = localStorage.getItem("admin-token");
  const date = new Date();
  const [user, setUser] = useState("");

  useEffect(() => {
    generateToken()
    if (token) {
      const decodedToken = jwtDecode(token);
      if (decodedToken.exp * 1000 < date.getTime()) {
        localStorage.removeItem("admin-token");
        navigate("/signin");
      } else {
        setUser(decodedToken);
        navigate("/");
      }
    } else {
      navigate("/signin");
    }
  }, [token]);

  return (
    <Fragment>
      {token ? (
        <>
          <Dashboard user={user.result} />
        </>
      ) : (
        <>
          <Routes>
            <Route path="/signin" element={<SignIn />} />
            <Route path="/agent/signin" element={<SignIn />} />
            {/* <Route path="/password" element={<ResetPassword />} /> */}
            {/* <Route path="/signup" element={<SignUp />} /> */}
          </Routes>
        </>
      )}
    </Fragment>
  );
}

export default App;
