import { Avatar, Backdrop, Box, Chip, CircularProgress, Divider, Grid, IconButton, Paper, Snackbar, Tooltip, Typography, } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import MuiAlert from "@mui/material/Alert";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import HeadLine from "../comman/HeadLine";
import './styles.css'
// import ProfileUpdate from "../popupcomp/ProfileUpdate";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const localUrl = process.env.REACT_APP_BASH_URL;
const imageUrl = process.env.REACT_APP_BASH_IMAGE_URL;
const textColor = "#3684f0";
function DriverDetails() {
  const navigate = useNavigate();
  const options = { Authorization: `Bearer ${localStorage.getItem("admin-token")}`, "Content-Type": "multipart/form-data", role: "admin", };

  let imageCommanUrl = imageUrl + "/images/drivers";

  const { id } = useParams();

  const [driver, setDriver] = useState("");
  const [user, setUser] = useState('')

  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState("");
  const [customVariant, setCustomVariant] = useState("error");
  const [refresh, setRefresh] = useState(false);
  // docuement
  const [adhar, setAdhar] = useState("");
  const [rc, setRc] = useState("");
  const [insurance, setInsurance] = useState("");
  const [pollution, setPollution] = useState("");
  const [passBook, setPassBook] = useState("");
  const [licence, setLicence] = useState("");
  const [frant, setFrant] = useState("");
  const [back, setBack] = useState("");

  const [booking, setBooking] = useState([]);
  const [openProfile, setOpenProfile] = useState(false);

  const [bankDetails, setBankDetails] = useState('')

  const [driverAreas, setDriverAreas] = useState([])

  // Dialog Compoenent

  const getSingleUser = async () => {
    return await axios.get(`${localUrl}/users/get-user/${id}`, { headers: options }).then((response) => {
      setUser(response.data.result)
      // console.log("resopnse: ", response.data.result);
    }).catch((error) => {
      console.log("error on getSingleUser: ", error);
    })
  }

  const getBooking = async () => {
    return await axios.get(`${localUrl}/booking/get-booking-driver-id/${id}`, { headers: options }).then((response) => {
      setBooking(response.data.result)
    }).catch((error) => {
      console.log("error on getSingleUser: ", error);
    })
  }

  const getBankDetails = async () => {
    return await axios.get(`${localUrl}/users/get-bank-details/${id}`, { headers: options }).then((response) => {
      setBankDetails(response.data.result)
    }).catch((err) => {
      console.log("error on getBankDetails: ", err);
    })
  }

  const getDriverAreas = async () => {
    return await axios.get(`${localUrl}/area/get-by-driver-id/${id}`, { headers: options }).then((response) => {
      setDriverAreas(response.data.result)
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      console.log("error on getDriverAreas: ", error);

    })
  }

  const getSingleDriver = async () => {
    return await axios.get(`${localUrl}/drivers/get-single-vehicle-details/${id}`, { headers: options }).then((response) => {
      setDriver(response.data.result);
      setLoading(false);
      // setBooking(response.data.getBooking);
    }).catch((err) => {
      // console.log("error on getSingleDriver: ", err);
      setSuccess("");
      setCustomVariant("error");
      setError(err?.response?.data?.msg);
      setOpen(true);
      setLoading(false);
    });
  };

  useEffect(() => {
    getSingleDriver();
    getSingleUser()
    getBooking()
    getBankDetails()
    getDriverAreas()
  }, [loading, refresh]);

  const handlAdharChange = async (e) => {
    e.preventDefault();
    setAdhar(URL.createObjectURL(e.target.files[0]));
    const formData = new FormData();
    formData.append("adhar", e.target.files[0]);
    formData.append("id", id);
    return await axios.post(`${localUrl}/drivers/aadhar-upload`, formData, { headers: options }).then((response) => {
      setError("");
      setSuccess(response.data.msg);
      setCustomVariant("success");
      setOpen(true);
      if (refresh) {
        setRefresh(false);
      } else {
        setRefresh(true);
      }
    }).catch((error) => {
      setSuccess("");
      setError(error.response.data.msg);
      setCustomVariant("error");
      setOpen(true);
    });
  };

  const handlRCChange = async (e) => {
    e.preventDefault();
    setRc(URL.createObjectURL(e.target.files[0]));
    const formData = new FormData();
    formData.append("vRC", e.target.files[0]);
    formData.append("id", id);
    return await axios.post(`${localUrl}/drivers/rc-upload'`, formData, { headers: options }).then((response) => {
      setError("");
      setSuccess(response.data.msg);
      setCustomVariant("success");
      setOpen(true);
      if (refresh) {
        setRefresh(false);
      } else {
        setRefresh(true);
      }
    }).catch((error) => {
      setSuccess("");
      setError(error.response.data.msg);
      setCustomVariant("error");
      setOpen(true);
    });
  };

  const handlLicenceChange = async (e) => {
    e.preventDefault();
    setLicence(URL.createObjectURL(e.target.files[0]));
    // setLicence1(e.target.files[0])
    const formData = new FormData();
    formData.append("licencePhoto", e.target.files[0]);
    formData.append("id", id);
    return await axios.post(`${localUrl}/drivers/licence-upload`, formData, { headers: options }).then((response) => {
      setError("");
      setSuccess(response.data.msg);
      setCustomVariant("success");
      setOpen(true);
      if (refresh) {
        setRefresh(false);
      } else {
        setRefresh(true);
      }
    }).catch((error) => {
      setSuccess("");
      setError(error.response.data.msg);
      setCustomVariant("error");
      setOpen(true);
    });
  };

  const handlpollutionChange = async (e) => {
    e.preventDefault();
    setPollution(URL.createObjectURL(e.target.files[0]));
    // setPollution1(e.target.files[0])
    const formData = new FormData();
    formData.append("pollution", e.target.files[0]);
    formData.append("id", id);

    // console.log("formData: ", formData);
    // console.log("");
    return await axios.post(`${localUrl}/drivers/pollution-upload`, formData, { headers: options, }).then((response) => {
      setError("");
      setSuccess(response.data.msg);
      setCustomVariant("success");
      setOpen(true);
      if (refresh) {
        setRefresh(false);
      } else {
        setRefresh(true);
      }
    }).catch((error) => {
      setSuccess("");
      setError(error.response.data.msg);
      setCustomVariant("error");
      setOpen(true);
    });
  };

  const handlinsuranceChange = async (e) => {
    e.preventDefault();
    setInsurance(URL.createObjectURL(e.target.files[0]));
    const formData = new FormData();
    formData.append("insurance", e.target.files[0]);
    formData.append("id", id);

    return await axios.post(`${localUrl}/drivers/insurance-upload`, formData, { headers: options, }).then((response) => {
      setError("");
      setSuccess(response.data.msg);
      setCustomVariant("success");
      setOpen(true);
      if (refresh) {
        setRefresh(false);
      } else {
        setRefresh(true);
      }
    }).catch((error) => {
      setSuccess("");
      setError(error.response.data.msg);
      setCustomVariant("error");
      setOpen(true);
    });
  };


  const handleFrantVehicleImage = async (e) => {
    e.preventDefault();
    setFrant(URL.createObjectURL(e.target.files[0]));
    const formData = new FormData();
    formData.append("frant", e.target.files[0]);
    formData.append("id", id);

    return await axios.post(`${localUrl}/drivers/frant-photo-upload`, formData, { headers: options, }).then((response) => {
      setError("");
      setSuccess(response.data.msg);
      setCustomVariant("success");
      setOpen(true);
      setRefresh(!refresh);

    }).catch((error) => {
      setSuccess("");
      setError(error.response.data.msg);
      setCustomVariant("error");
      setOpen(true);
    });
  };


  const handleBackChange = async (e) => {
    e.preventDefault();
    setBack(URL.createObjectURL(e.target.files[0]));
    const formData = new FormData();
    formData.append("backPhoto", e.target.files[0]);
    formData.append("id", id);

    return await axios.post(`${localUrl}/drivers/back-photo-upload`, formData, { headers: options, }).then((response) => {
      setError("");
      setSuccess(response.data.msg);
      setCustomVariant("success");
      setOpen(true);
      setRefresh(!refresh);
    }).catch((error) => {
      setSuccess("");
      setError(error.response.data.msg);
      setCustomVariant("error");
      setOpen(true);
    });
  };

  const handlsetPassBookChange = async (e) => {
    e.preventDefault();
    setPassBook(URL.createObjectURL(e.target.files[0]));
    // setValError({ ...valError, passBook: '' })
    // setPassBook1(e.target.files[0])
    const formData = new FormData();
    formData.append("passBookPhoto", e.target.files[0]);
    formData.append("id", id);

    // console.log("formData: ", formData);
    // console.log("");
    return await axios.post(`${localUrl}/drivers/passbook-upload`, formData, { headers: options, }).then((response) => {
      setError("");
      setSuccess(response.data.msg);
      setCustomVariant("success");
      setOpen(true);
      if (refresh) {
        setRefresh(false);
      } else {
        setRefresh(true);
      }
    }).catch((error) => {
      setSuccess("");
      setError(error.response.data.msg);
      setCustomVariant("error");
      setOpen(true);
    });
  };


  return (
    <div >
      {/* <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}> <Alert onClose={() => setOpen(false)} severity={'error'} sx={{ width: '100%' }}> {error ? error : ""} </Alert> </Snackbar> */}

      <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)} anchorOrigin={{ vertical: "top", horizontal: "right" }} key={"top" + "right"}>
        <Alert onClose={() => setOpen(false)} severity={customVariant} sx={{ width: "100%" }}>{error ? error : success}</Alert>
      </Snackbar>

      {/* <div style={{display: "flex",flexDirection: "row",justifyContent: "space-between",alignItems: "center",marginRight: "10px",}}> */}
      <Box sx={{ display: "flex" }}>
        <IconButton onClick={() => navigate(-1)}>
          <KeyboardBackspaceIcon />
        </IconButton>
        <Typography variant="h5" component={"div"} fontWeight={700} sx={{ textDecoration: "underline", textUnderlineOffset: 3, marginLeft: 2, marginTop: 0.5, }}>Driver Details</Typography>
      </Box>
      {/* </div> */}
      {/* Profile update Dialog */}
      {/* <ProfileUpdate openProfile={openProfile} id={driver.id} setOpenProfile={setOpenProfile} setSuccess={setSuccess} setError={setError} setCustomVariant={setCustomVariant} setOpen={setOpen} refresh={refresh} setRefresh={setRefresh} /> */}
      {/* <CircularProgress /> */}

      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}><CircularProgress color="inherit" /></Backdrop>

      <Grid spacing={2} container>
        <Grid item xs={12} md={4} lg={4}>
          <Paper elevation={3} sx={{ minHeight: 355 }}>
            <Box style={{ flexDirection: "row", alignItems: "center", display: "flex", justifyContent: "space-between", }}>
              <Typography variant="h5" sx={{ textDecoration: "underline", fontWeight: "bold", color: "#1976d2", p: 2, }}>Profile</Typography>

              <Tooltip title="Edit Profile" onClick={() => setOpenProfile(true)}>
                <Link to={`/user/update/${user.id}`}><IconButton style={{ color: "orange" }}><DriveFileRenameOutlineOutlinedIcon /></IconButton></Link>
              </Tooltip>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Avatar src={`${imageUrl}/images/profile/${user?.image}`} sx={{ width: 90, height: 90 }} alt={driver?.name} />
            </Box>

            <Box sx={{ padding: 2, }}>
              <Typography>Name: {user?.name}</Typography>
              {user.email && <Typography>Email: {user?.email}</Typography>}
              <Typography>Mobile No.: {user?.mobile}</Typography>
              <Typography>Address: {user?.address}</Typography>
              <Typography>Area: {user?.area}</Typography>
              <Typography>School Name: {user?.school_name}</Typography>
              <Typography>Refer Code: {user?.refer_code}</Typography>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4} lg={4}>
          <Paper elevation={3} sx={{ px: 2, minHeight: 355 }}>

            <Typography variant="h5" sx={{ textDecoration: "underline", fontWeight: "bold", textAlign: "center", p: 2, color: "#1976d2", }}>Vehicle Details</Typography>


            <Typography>Vehicle Name: {driver?.vehicleName || ""}</Typography>
            <Typography>Vehicle Number: {driver?.vehicleNumber || ""}</Typography>
            <Typography>Vehicle Color: {driver?.vehicleColor || ""}</Typography>


            {/* <Typography>Number of available Seats: {driver?.avilableSeat || ""}</Typography> */}
            <Typography>Number of Seats: {driver?.noSeat || ""}</Typography>
            <Tooltip title="Click to view booking"><Link to={`/driver/bookingDetails/${id}`} style={{ textDecoration: "none", color: "inherit" }}><Typography>Number of Bookings: {booking?.length || 0}</Typography></Link></Tooltip>

            <Typography>Owner Name: {driver?.vOwnerName || ""}</Typography>

            <Typography>Owner Number: {driver.vOwnerPhone == "undefined" ? "" : driver?.vOwnerPhone || ""}</Typography>

            <Typography>Start Point: {user.area || ""}</Typography>


          </Paper>
        </Grid>


        {/* bank details */}
        <Grid item xs={12} md={4} lg={4}>
          <Paper elevation={3} sx={{ px: 2, minHeight: 355 }}>

            <Typography variant="h5" sx={{ textDecoration: "underline", fontWeight: "bold", textAlign: "center", p: 2, color: "#1976d2", }}>Bank Details</Typography>
            {/* <img src={`${imageCommanUrl}/bankPassbook/${bankDetails.image}`} alt={driver.name} style={{ width: '100%', height: 100 }} /> */}

            <Typography>A/C Number: {bankDetails?.acNumber || ""}</Typography>
            <Typography>Bank Name: {bankDetails?.bankName || ""}</Typography>
            <Typography>IFSC Code: {bankDetails?.ifsc || ""}</Typography>

            <Typography>Branch Name: {bankDetails?.branch || ""}</Typography>
            {/* <Link to={`/driver/bookingDetails/${id}`} style={{ textDecoration: "none", color: "inherit" }}><Typography>Number of Bookings: {booking?.length || 0}</Typography></Link> */}

            {/* <Typography>Bank Address: {bankDetails?.address || ""}</Typography> */}

            <Typography>UPI Id: {bankDetails?.upi || ""}</Typography>

            <Typography variant="h5" sx={{ textDecoration: "underline", fontWeight: "bold", textAlign: "center", p: 2, color: "#1976d2", mt: 3 }}>Areas</Typography>

            <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 1, pb: 1 }}>
              <Grid spacing={2} container>
                {driverAreas?.map((item) => { return <Grid item lg={3} md={4} sm={6} xs={12}><Chip label={item?.area?.name} variant="outlined" /> </Grid> })}
              </Grid>
            </Box>
            {/* <Box sx={{ display: 'flex', width: '100%', border: '1px solid red', justifyContent: 'space-between', }}>
              {driverAreas?.map((item, ind) => {
                return (<Typography sx={{ display: 'flex' }}>{item?.area?.name}</Typography>)
              })}
            </Box> */}


          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item md={4} xs={12} sm={6} lg={3}>
          {/* <Box sx={{ background: textColor, width: '100%', height: 35, px: 1, }}><Typography color={'white'}>Adhar Card</Typography></Box> */}
          <HeadLine title={'Adhar Card'} />

          <label htmlFor={"adharCard"}>
            <Tooltip title="Click to upload adhar card">
              <Box sx={{ width: "100%", height: "90%", display: "flex", alignItems: "center", justifyContent: "center", backgroundPosition: "contain", }}>
                {driver && driver?.aadhar ? (<><img style={{ objectFit: "contain", }} src={adhar ? adhar : driver?.aadhar && `${imageCommanUrl}/adhar/${driver?.aadhar}`} alt={"Adhar Card"} className="driverProfile" /></>) : (<><img style={{ objectFit: "contain", }} src={`/images/notFound.jpg`} alt="Logo" className={"driverProfile"} /></>)}
              </Box>
            </Tooltip>
          </label>
          <input type="file" style={{ display: "none" }} name="photo" id={"adharCard"} onChange={handlAdharChange} />

        </Grid>

        <Grid item md={4} xs={12} sm={6} lg={3}>
          {/* <Box sx={{ background: textColor, width: '100%', height: 35, px: 1, }}><Typography color={'white'}>Adhar Card</Typography></Box> */}
          <HeadLine title={'R.C.'} />
          <label htmlFor={"RC"}>
            <Tooltip title="Click to upload R.C.">
              <Box sx={{ width: "100%", height: "90%", display: "flex", alignItems: "center", justifyContent: "center", backgroundPosition: "cover", }}>
                {driver && driver?.vRC ? (<><img style={{ objectFit: "contain", }} src={rc ? rc : driver && driver?.vRC && `${imageCommanUrl}/rc/${driver?.vRC}`} alt={"R. C."} className={"driverProfile"} /></>) : (<><img style={{ objectFit: "contain", }} src={`/images/notFound.jpg`} alt="Logo" className={"driverProfile"} /></>)}
              </Box>
            </Tooltip>
          </label>
          <input type="file" style={{ display: "none" }} name="photo" id={"RC"} onChange={handlRCChange} />

        </Grid>

        <Grid item md={4} xs={12} sm={6} lg={3}>

          <HeadLine title={'Pollution'} />
          <label htmlFor={"pollution"}>
            <Tooltip title="Click to upload Pollution">
              <Box sx={{ width: "100%", height: "90%", display: "flex", alignItems: "center", justifyContent: "center", backgroundPosition: "cover", }}>
                {driver && driver?.pollution ? (
                  <><img style={{ objectFit: "contain", }} src={pollution ? pollution : driver && driver?.pollution && `${imageCommanUrl}/pollution/${driver?.pollution}`} alt={"Pollution"} className={"driverProfile"} /></>
                ) : (
                  <><img style={{ objectFit: "contain", }} src={`/images/notFound.jpg`} alt="Logo" className={"driverProfile"} /></>
                )}
              </Box>
            </Tooltip>
          </label>
          <input type="file" style={{ display: "none" }} name="photo" id={"pollution"} onChange={handlpollutionChange} />
        </Grid>

        <Grid item md={4} xs={12} sm={6} lg={3}>

          <HeadLine title={'Insurance'} />
          <label htmlFor={"insurance"}>
            <Tooltip title="click to upload insurance">
              <Box sx={{ width: "100%", height: "90%", display: "flex", alignItems: "center", justifyContent: "center", backgroundPosition: "cover", }}>
                {driver && driver?.insurance ? (
                  <><img style={{ objectFit: "contain", }} src={insurance ? insurance : driver && driver?.insurance && `${imageCommanUrl}/insurance/${driver?.insurance}`} alt={"Insurance"} className={"driverProfile"} /></>
                ) : (
                  <><img style={{ objectFit: "contain", }} src={`/images/notFound.jpg`} alt="Logo" className={"driverProfile"} /></>
                )}
              </Box>
            </Tooltip>
          </label>
          <input type="file" style={{ display: "none" }} name="photo" id={"insurance"} onChange={handlinsuranceChange} />
        </Grid>

        <Grid item md={4} xs={12} sm={6} lg={3}>

          <HeadLine title={'Licence'} />
          <label htmlFor={"DrivingLicence"}>
            <Tooltip title="Click to upload Driving Licence">
              <Box sx={{ width: "100%", height: "90%", display: "flex", alignItems: "center", justifyContent: "center", backgroundPosition: "cover", }}>
                {driver && driver?.licencePhoto ? (
                  <><img style={{ objectFit: "contain", }} src={licence ? licence : driver && driver?.licencePhoto && `${imageCommanUrl}/licences/${driver?.licencePhoto}`} alt={"Driving Licence"} className={"driverProfile"} /></>
                ) : (
                  <><img style={{ objectFit: "contain", }} src={`/images/notFound.jpg`} alt="Logo" className={"driverProfile"} /></>
                )}
              </Box>
            </Tooltip>
          </label>
          <input type="file" style={{ display: "none" }} name="photo" id={"DrivingLicence"} onChange={handlLicenceChange} />
        </Grid>

        {/* <Grid item md={4} xs={12} sm={6} lg={3}>

          <HeadLine title={'Bank Passbook'} />
          <label htmlFor={"passBook"}>
            <Tooltip title="Click to upload Bank Passbook">
              <Box sx={{ width: "100%", height: "90%", display: "flex", alignItems: "center", justifyContent: "center", backgroundPosition: "cover", }}>
                {driver && driver?.passBookPhoto ? (<><img style={{ objectFit: "contain", }} src={passBook ? passBook : driver && driver?.passBookPhoto && `${imageCommanUrl}/bankPassbook/${driver?.passBookPhoto}`} alt={"Bank Pass Book"} className={"driverProfile"} /></>
                ) : (
                  <><img style={{ objectFit: "contain", }} src={`/images/notFound.jpg`} alt="Logo" className={"driverProfile"} /></>
                )}
              </Box>
            </Tooltip>
          </label>
          <input type="file" style={{ display: "none" }} name="photo" id={"passBook"} onChange={handlsetPassBookChange} />
        </Grid> */}


        <Grid item md={4} xs={12} sm={6} lg={3}>

          <HeadLine title={'Vehicle Front Photo'} />
          <label htmlFor={"frant"}>
            <Tooltip title="Click to upload vehicle front photo">
              <Box sx={{ width: "100%", height: "90%", display: "flex", alignItems: "center", justifyContent: "center", backgroundPosition: "cover", }}>
                {driver && driver?.frant ? (<><img style={{ objectFit: "contain", }} src={frant ? frant : driver && driver?.frant && `${imageCommanUrl}/frant/${driver?.frant}`} alt={"Bank Pass Book"} className={"driverProfile"} /></>
                ) : (
                  <><img style={{ objectFit: "contain", }} src={`/images/notFound.jpg`} alt="Logo" className={"driverProfile"} /></>
                )}
              </Box>
            </Tooltip>
          </label>
          <input type="file" style={{ display: "none" }} name="photo" id={"frant"} onChange={handleFrantVehicleImage} />
        </Grid>

        <Grid item md={4} xs={12} sm={6} lg={3}>

          <HeadLine title={'Vehicle Back Photo'} />
          <label htmlFor={"back"}>
            <Tooltip title="Click to upload vehicle back photo">
              <Box sx={{ width: "100%", height: "90%", display: "flex", alignItems: "center", justifyContent: "center", backgroundPosition: "cover", }}>
                {driver && driver?.back ? (<><img style={{ objectFit: "contain", }} src={back ? back : driver && driver?.back && `${imageCommanUrl}/back/${driver?.back}`} alt={"Bank Pass Book"} className={"driverProfile"} /></>
                ) : (
                  <><img style={{ objectFit: "contain", }} src={`/images/notFound.jpg`} alt="Logo" className={"driverProfile"} /></>
                )}
              </Box>
            </Tooltip>
          </label>
          <input type="file" style={{ display: "none" }} name="photo" id={"back"} onChange={handleBackChange} />
        </Grid>

      </Grid>
    </div >
  );
}

export default DriverDetails;
