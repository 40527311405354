import { Box } from '@mui/material';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';

const socketUrl = process.env.REACT_APP_SOCKET_URL;
const socket = io(socketUrl);

const bashUrl = process.env.REACT_APP_BASH_URL;
const imageUrl = process.env.REACT_APP_BASH_IMAGE_URL;

const mapContainerStyle = {
    width: '100%',
    height: '75vh'
};

const defaultCenter = {
    lat: 22.828629022443526,
    lng: 86.218885
};

function Tracking() {
    const options = { Authorization: `Bearer ${localStorage.getItem("admin-token")}`, "Content-Type": "application/json", role: "admin", };
    const [drivers, setDrivers] = useState([]);
    const [liveLocations, setLiveLocations] = useState(new Map());
    const [loading, setLoading] = useState(true);

    const getAllDrivers = async () => {
        try {
            const response = await axios.get(`${bashUrl}/users/get-user-by-role?role=driver&online=true`, { headers: options });
            // console.log("response: ", response.data.result);

            setDrivers(response.data.result);
            setLoading(false);
        } catch (error) {
            console.log("error: ", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        getAllDrivers();
    }, []);

    // console.log("liveLocatinos: ", liveLocations);

    useEffect(() => {
        drivers.forEach(driver => {
            socket.on(`driverTracking_${driver.id}`, (data, driver) => {
                console.log("data: ", data, driver);
                setLiveLocations(prevLocations => {
                    const newLocations = new Map(prevLocations);
                    newLocations.set(driver.id, { latitude: data?.latitude, longitude: data?.longitude, name: driver?.name, vNumber: driver?.driver?.vehicleNumber });
                    return newLocations;
                });
            });
        });

        return () => {
            drivers.forEach(driver => {
                // console.log("driver: ", driver);
                socket.off(`driverTracking_${driver.id}`);
            });
        };
    }, [drivers]);

    const customIcon = { url: `${imageUrl}/images/marker/car_marker.png`, scaledSize: new window.google.maps.Size(25, 35) };

    return (
        <Box sx={{ width: '100%', height: '75vh' }}>
            {/* <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}> */}
            <GoogleMap mapContainerStyle={mapContainerStyle} center={defaultCenter} zoom={10}>
                {Array.from(liveLocations.entries()).map(([driverId, location]) => (
                    <Marker key={driverId} position={{ lat: location.latitude, lng: location.longitude }} title={`Driver: ${location?.name} || Vehicle Number: ${location?.vNumber}`} icon={customIcon} />
                    // <Marker key={driverId} position={{ lat: location.latitude, lng: location.longitude }} title={`Driver ${drivers.map((item, ind) => driverId == item.id ? item.name : '')}`} />
                ))}
                {/* {drivers?.map((item, ind) => {
                    socket.on(`driverTracking_${item.id}`, (data, driver) => {
                        console.log("data: ", data);
                        console.log("driver: ", driver);
                        return <Marker key={item?.id} position={{ lat: data.latitude, lng: data.longitude }} title={`Driver: ${driver?.name} || Vehicle Number: ${driver?.vehicleNumber}`} icon={customIcon} />
                    })
                })} */}
            </GoogleMap>
            {/* </LoadScript> */}
        </Box>
    );
}

export default Tracking;