import { Box, Button, Dialog, DialogTitle, Divider, IconButton, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { grey } from '@mui/material/colors';
import LoadingButton from '@mui/lab/LoadingButton';


const basURL = process.env.REACT_APP_BASH_URL

function BookingConfirm({ openDailog, setOpenDailog, admin, refresh, setRefresh, setSuccess, setError, setCustomVariant, setOpen, data, setData, loading, setLoading }) {
    const options = { Authorization: `Bearer ${localStorage.getItem("admin-token")}`, "Content-Type": "application/json", role: "admin", };

    const [circle, setCircle] = useState(false)


    const handleClose = () => {
        setOpenDailog(false)
        setCircle(false)
        setData("")
    }

    const handleClick = async (evt) => {
        evt.preventDefault()
        setCircle(true)
        setLoading(true)
        // console.log("data: ", data);
        return await axios.post(`${basURL}/booking/status-change`, data, { headers: options }).then((response) => {
            // console.log("response: ", response.data);
            setError('')
            setSuccess(response.data.msg)
            setCustomVariant('success')
            setOpen(true)
            setLoading(false)
            setRefresh(!refresh)
            setCircle(false)
            handleClose()
        }).catch((error) => {
            console.log("error: ", error);
            setSuccess('')
            setError(error.response.data.msg)
            setCustomVariant('error')
            setOpen(true)
            setCircle(false)
            setLoading(false)
            handleClose()
        })
    }


    return (
        <Dialog open={openDailog} onClose={handleClose} scroll={'paper'} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
            <Box sx={{ p: 2 }}>
                <Typography id="keep-mounted-modal-title" variant="h6" component="h2" color="error">Are you sure you want to {data.active ? 'active' : 'de-active'} this booking?</Typography>
                <Divider />
                <Box sx={{ display: "flex", mt: 2 }}>
                    {circle ? <LoadingButton loading variant="contained" sx={{}}>Yes</LoadingButton> : <Button style={{ backgroundColor: "green", color: "white" }} variant="outlined" color="success" onClick={handleClick}>Yes</Button>}
                    <Box sx={{ flexGrow: 1 }} />
                    <Button style={{ backgroundColor: "red", color: "white" }} variant="outlined" color="error" onClick={handleClose}>Cancel</Button>
                    {/* {circle ? <LoadingButton loading fullWidth variant="contained" sx={{ mt: 3, mb: 2, }}>Sign In</LoadingButton> : <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: "#108A00", '&:hover': { bgcolor: "#0D6E00" } }}>Sign In</Button>} */}
                </Box>
            </Box>
        </Dialog>
    )
}

export default BookingConfirm