import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import MuiAlert from "@mui/material/Alert";
import { Backdrop, Box, Button, CircularProgress, IconButton, InputAdornment, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchIcon from "@mui/icons-material/Search";
import ModaleComponent from '../popup/ModaleComponent';
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import moment from 'moment';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const bashUrl = process.env.REACT_APP_BASH_URL;

function Refer({ refRole }) {
    const options = { Authorization: `Bearer ${localStorage.getItem("admin-token")}`, "Content-Type": "application/json", role: "admin", };
    const Navigate = useNavigate();

    const [teachers, setTeachers] = useState([]);
    const [filterdData, setFilterData] = useState([]);

    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(true);

    const [search, setSearch] = useState("");

    const [openModale, setOpenModale] = useState(false);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    // const [data, setData] = useState({ name: "", id: "", role: "", });

    const [open, setOpen] = useState(false);
    const [customVariant, setCustomVariant] = useState("success");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState();


    const [modaleOpen, setModaleOpen] = useState(false);

    const [data, setData] = useState({ parentId: "", id: "", role: "", name: "", });
    // console.log("role: ", refRole);

    // get-user-by-role
    const getAllUsers = async () => {
        return await axios.get(`${bashUrl}/refers/get-all?role=${refRole}`, { headers: options }).then((response) => {
            setTeachers(response.data.result)
            setFilterData(response.data.result)
            setLoading(false)
            console.log("response: ", response.data.result);
        }).catch((err) => {
            setLoading(false)
            console.log("error on getAllUsers: ", err);
            if (err?.response?.status == 401) {
                localStorage.removeItem("admin-token")
            }
        })
    }

    // this is fro seraching data of driver
    useEffect(() => {
        const searchData = teachers.filter((item) =>
            item?.name.toLowerCase().includes(search.toLocaleLowerCase()) ? item : null
        );
        setFilterData(searchData);
    }, [search]);

    // This is for Designing
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        getAllUsers()
    }, [loading, refresh, refRole])

    return (
        <Box>
            <Snackbar open={open} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "right" }} key={"top" + "right"} onClose={() => setOpen(false)}>
                <Alert onClose={() => setOpen(false)} severity={customVariant} sx={{ width: "100%" }}>
                    {error ? error : success}
                </Alert>
            </Snackbar>

            {/* <CircularProgress /> */}
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <ModaleComponent openModale={modaleOpen} setOpenModale={setModaleOpen} data={data} setData={setData} setCustomVariant={setCustomVariant} setOpen={setOpen} setSuccess={setSuccess} setError={setError} refresh={refresh} setRefresh={setRefresh} />

            <Box sx={{ width: "100%", pr: 2 }}>
                <Box sx={{ width: "100%", paddingY: 2 }}>
                    <Box sx={{ flexGrow: 1 }} />

                    <Box sx={{ width: "40%" }}>
                        <TextField fullWidth label="Search..." name="search" onChange={(evt) => setSearch(evt.target.value)} value={search} placeholder="Search by name" id="outlined-start-adornment"
                            InputProps={{ startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>), }}
                        />
                    </Box>
                </Box>

                <TableContainer component={Paper}>
                    <Table /* aria-label='collapsible table' */>
                        <TableHead>
                            <TableRow>
                                {/* <TableCell>Profile</TableCell> */}
                                <TableCell >Name</TableCell>
                                <TableCell align="center">Email</TableCell>
                                <TableCell align="center">Phone</TableCell>
                                <TableCell align="center">Refer By</TableCell>
                                <TableCell align="center"> Role </TableCell>
                                <TableCell align="center">Register</TableCell>
                                <TableCell align="center">Refer Date</TableCell>
                                <TableCell align="center"> Actions </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filterdData && filterdData.length ? (
                                filterdData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, ind) => {
                                    return (
                                        <TableRow key={ind}>
                                            <TableCell sx={{ color: "black" }} component="th" scope="row">{item?.name}</TableCell>
                                            <TableCell sx={{ color: "black" }} align="center">{item?.email}{" "}</TableCell>
                                            <TableCell sx={{ color: "black" }} align="center">{item?.mobile}</TableCell>
                                            <TableCell sx={{ color: "black" }} align="center">{item?.user?.name}{" "}</TableCell>
                                            <TableCell sx={{ color: "black", textTransform: 'capitalize' }} align="center">{item?.role}{" "}</TableCell>
                                            <TableCell sx={{ color: "black", textTransform: 'capitalize' }} align="center">{item?.is_register ? 'Yes' : 'No'}</TableCell>
                                            <TableCell sx={{ color: "black", textTransform: 'capitalize' }} align="center">{moment(item?.createdAt).format("LLL")}</TableCell>
                                            <TableCell sx={{}} align="center">
                                                <Tooltip title="View Details"><Link to={`/refer-view/${item.id}`} style={{ textDecoration: "none" }}><IconButton color="success"><VisibilityIcon /></IconButton>{" "}</Link>{" "}</Tooltip>
                                                {/* <Tooltip title="Update"><Link to={`/user/update/${item.id}`} style={{ textDecoration: "none" }}><IconButton color="warning"><DriveFileRenameOutlineOutlinedIcon /></IconButton>{" "}</Link>{" "}</Tooltip> */}
                                                <Tooltip title="Delete Teacher">
                                                    <IconButton color="error" onClick={() => { setModaleOpen(true); setData({ ...data, id: item.id, role: "refer", name: item?.name, }); }}>{" "}<DeleteForeverOutlinedIcon />{" "}</IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={8} align="center">
                                        <Typography>No {refRole == 'parent' && "Parent"}{refRole == 'driver' && "Driver"}{refRole == 'teacher' && "Teacher"} data found!</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <TablePagination rowsPerPageOptions={[10, 25, 100]} component="div" count={filterdData?.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
                </TableContainer>
            </Box>
        </Box>
    )
}

export default Refer