import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import { Dialog, DialogTitle, Divider, IconButton, TextField, Backdrop, Box, Button, CircularProgress, Paper, Snackbar, Table, TableBody, TableCell, TableHead, TableRow, Grid, Typography } from '@mui/material';
import axios from 'axios';
import MuiAlert from "@mui/material/Alert";
import PopUpSetting from '../popup/PopUpSetting';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const bashUrl = process.env.REACT_APP_BASH_URL;

function Setting() {
    const options = { Authorization: `Bearer ${localStorage.getItem("admin-token")}`, "Content-Type": "application/json", role: "admin", };
    const Navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const [open, setOpen] = useState(false);
    const [customVariant, setCustomVariant] = useState("success");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState();

    /* const [openDailog, setOpenDailog] = useState(false)

    const [setting, setSetting] = useState('');

    const [loading, setLoading] = useState(true);

    const [open, setOpen] = useState(false);
    const [customVariant, setCustomVariant] = useState("success");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState();

    const getSingleSettingData = async () => {
        return await axios.get(`${bashUrl}/setting/get`, { headers: options }).then((response) => {
            setSetting(response.data.result)
            setLoading(false)
            // console.log("response: ", response.data.result);
        }).catch((error) => {
            setLoading(false)
            setSuccess("")
            setError(error.response.data.msg)
            setCustomVariant("error")
            setOpen(true)
            console.log("error on getSingleSettingData: ", error);
        })
    } */

    // const options = { 'Authorization': `Bearer ${localStorage.getItem('admin-token')}`, 'Content-Type': 'multipart/form-data', 'role': 'admin' }

    const [setting, setSetting] = useState({
        parentCommision: '',
        refParentAmount: '',
        refDriverAmount: '',
        leadAmountDriver: '',
        leadAmoutParent: '',
        driverCommision: '',
        fine: '',
        teacherCommision: '',
        about: ''
    })

    const [valSetting, setValSetting] = useState({
        parentCommision: '',
        refParentAmount: '',
        refDriverAmount: '',
        leadAmountDriver: '',
        leadAmoutParent: '',
        driverCommision: '',
        fine: '',
        teacherCommision: '',
        about: '',
    })

    const handleOnChange = (evt) => {
        setSetting({ ...setting, [evt.target.name]: evt.target.value })
        setValSetting({
            parentCommision: '',
            refParentAmount: '',
            refDriverAmount: '',
            leadAmountDriver: '',
            leadAmoutParent: '',
            driverCommision: '',
            fine: '',
            teacherCommision: ''
        })
    }

    const handleClose = () => {
        setSetting({
            parentCommision: '',
            refParentAmount: '',
            refDriverAmount: '',
            leadAmountDriver: '',
            leadAmoutParent: '',
            driverCommision: '',
            fine: '',
            teacherCommision: ''
        })
        setValSetting({
            parentCommision: '',
            refParentAmount: '',
            refDriverAmount: '',
            leadAmountDriver: '',
            leadAmoutParent: '',
            driverCommision: '',
            fine: '',
            teacherCommision: ''
        })
    }

    const getSingleSettingData = async () => {
        return await axios.get(`${bashUrl}/setting/get`, { headers: options }).then((response) => {
            setSetting(response.data.result)
            setLoading(false)
            // console.log("response: ", response.data.result);
        }).catch((error) => {
            console.log("error on getSingleSettingData: ", error);
            setLoading(false)
        })
    }



    useEffect(() => {
        getSingleSettingData()
    }, [loading])


    const handlSubmit = async (evt) => {
        evt.preventDefault()
        if (!setting.refParentAmount) {
            setValSetting({ ...valSetting, refParentAmount: 'Refer parent amount is required!' })
        } else if (!setting.refDriverAmount) {
            setValSetting({ ...valSetting, refDriverAmount: 'Refer driver amount is required!' })
        } else if (!setting.leadAmountDriver) {
            setValSetting({ ...valSetting, leadAmountDriver: 'Driver lead amount is required!' })
        } else if (!setting.leadAmoutParent) {
            setValSetting({ ...valSetting, leadAmoutParent: 'Parent lead amount is required!' })
        } else if (!setting.parentCommision) {
            setValSetting({ ...valSetting, parentCommision: 'Parent commission is required!' })
        } else if (!setting.driverCommision) {
            setValSetting({ ...valSetting, driverCommision: 'Driver commission is required!' })
        } else if (!setting.teacherCommision) {
            setValSetting({ ...valSetting, teacherCommision: 'Teacher commission is required!' })
        } else if (!setting.fine) {
            setValSetting({ ...valSetting, fine: 'Fine is required!' })
        } else if (!setting.about) {
            setValSetting({ ...setting, about: 'About is required!' })
        } else {
            return await axios.post(`${bashUrl}/setting/add`, setting, { headers: options }).then((response) => {
                setError("")
                setSuccess(response.data.msg)
                setCustomVariant("success")
                setOpen(true)
                setLoading(true)
                handleClose()
            }).catch((error) => {
                setSuccess("")
                setError(error.response.data.msg)
                setCustomVariant("error")
                setOpen(true)
                console.log("error on handlSubmit: ", error);
            })
        }
    }

    return (
        <Box>
            <Snackbar open={open} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "right" }} key={"top" + "right"} onClose={() => setOpen(false)}>
                <Alert onClose={() => setOpen(false)} severity={customVariant} sx={{ width: "100%" }}>
                    {error ? error : success}
                </Alert>
            </Snackbar>

            {/* <CircularProgress /> */}
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* <PopUpSetting openDailog={openDailog} setOpenDailog={setOpenDailog} refresh={loading} setRefresh={setLoading} setSuccess={setSuccess} setError={setError} setCustomVariant={setCustomVariant} setOpen={setOpen} /> */}

            <Box component={'form'} noValidate sx={{ width: '100%', }} onSubmit={handlSubmit}>
                <Box sx={{ width: '100%', display: 'flex', mb: 2 }}>
                    <Box sx={{ flexGrow: 1 }} />
                    {/* <Button variant='contained' type='submit'>Submit</Button> */}
                </Box>

                <Paper sx={{ pb: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', pr: 1 }}>
                        <DialogTitle id="scroll-dialog-title">Setting</DialogTitle>
                    </Box>
                    <Divider />
                    <Grid spacing={2} container sx={{ px: 1 }}>
                        <Grid item xs={3}>
                            <TextField type='number' name='refParentAmount' id="outlined-basic" label="Refer Parent Amount" placeholder='Enter refer parent amount!' variant="outlined" value={setting.refParentAmount} onChange={handleOnChange} error={valSetting.refParentAmount ? true : false} helperText={valSetting.refParentAmount ? valSetting.refParentAmount : ''} fullWidth sx={{ my: 1 }} autoFocus={true} />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField type='number' name='refDriverAmount' id="outlined-basic" label="Refer Driver Amount" placeholder='Enter refer driver amount!' variant="outlined" value={setting.refDriverAmount} onChange={handleOnChange} error={valSetting.refDriverAmount ? true : false} helperText={valSetting.refDriverAmount ? valSetting.refDriverAmount : ''} fullWidth sx={{ my: 1 }} autoFocus={true} />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField type='number' name='leadAmountDriver' id="outlined-basic" label="Driver Lead Amount" placeholder='Enter driver lead amount!' variant="outlined" value={setting.leadAmountDriver} onChange={handleOnChange} error={valSetting.leadAmountDriver ? true : false} helperText={valSetting.leadAmountDriver ? valSetting.leadAmountDriver : ''} fullWidth sx={{ my: 1 }} autoFocus={true} />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField type='number' name='leadAmoutParent' id="outlined-basic" label="Parent Lead Amount" placeholder='Enter parent lead amount!' variant="outlined" value={setting.leadAmoutParent} onChange={handleOnChange} error={valSetting.leadAmoutParent ? true : false} helperText={valSetting.leadAmoutParent ? valSetting.leadAmoutParent : ''} fullWidth sx={{ my: 1 }} autoFocus={true} />
                        </Grid>

                        <Grid item xs={3}>
                            <TextField type='number' name='parentCommision' id="outlined-basic" label="Parent Commision" placeholder='Enter parent commission!' variant="outlined" value={setting.parentCommision} onChange={handleOnChange} error={valSetting.parentCommision ? true : false} helperText={valSetting.parentCommision ? valSetting.parentCommision : ''} fullWidth sx={{ my: 1 }} autoFocus={true} />

                        </Grid>
                        <Grid item xs={3}>
                            <TextField type='number' name='driverCommision' id="outlined-basic" label="Driver Commission" placeholder='Enter driver commission!' variant="outlined" value={setting.driverCommision} onChange={handleOnChange} error={valSetting.driverCommision ? true : false} helperText={valSetting.driverCommision ? valSetting.driverCommision : ''} fullWidth sx={{ my: 1 }} autoFocus={true} />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField type='number' name='teacherCommision' id="outlined-basic" label="Teacher Commission" placeholder='Enter teacher commission!' variant="outlined" value={setting.teacherCommision} onChange={handleOnChange} error={valSetting.teacherCommision ? true : false} helperText={valSetting.teacherCommision ? valSetting.teacherCommision : ''} fullWidth sx={{ my: 1 }} autoFocus={true} />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField type='number' name='fine' id="outlined-basic" label="Fine" placeholder='Enter fine!' variant="outlined" value={setting.fine} onChange={handleOnChange} error={valSetting.fine ? true : false} helperText={valSetting.fine ? valSetting.fine : ''} fullWidth sx={{ my: 1 }} autoFocus={true} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                type='text'
                                name='about'
                                id="outlined-basic"
                                label="About"
                                placeholder='Enter about!'
                                variant="outlined"
                                value={setting.about}
                                onChange={(e) => {
                                    if (e.target.value.length <= 400) {
                                        handleOnChange(e); // Only allow the update if character count is <= 400
                                    }
                                }}
                                error={valSetting.about ? true : false}
                                helperText={valSetting.about ? valSetting.about : ''}
                                fullWidth
                                sx={{ my: 1 }}
                                autoFocus={true}
                                multiline
                                rows={4}
                            />
                            <Typography color={setting.about.length === 400 ? "error" : 'black'} variant="caption">
                                {setting.about.length}/400
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Button variant='contained' sx={{ mt: 1 }} type='submit'> Submit </Button>
                    </Box>
                </Paper>
            </Box>
        </Box>
    )
}

export default Setting