import React, { useEffect, useState } from 'react'
import { Backdrop, Box, Button, CircularProgress, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Paper, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import SearchIcon from "@mui/icons-material/Search";
import moment from 'moment';
import MuiAlert from '@mui/material/Alert';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

// Alert notification of MUI
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



const localUrl = process.env.REACT_APP_BASH_URL;
function Payment() {

    const options = { Authorization: `Bearer ${localStorage.getItem("admin-token")}`, "Content-Type": "application/json", role: "admin", };
    const navigate = useNavigate();

    const [booking, setBooking] = useState([])

    const [selection, setSelection] = useState({
        month: null,
        year: null
    })
    const [valSelection, setValSelection] = useState('')

    const [filterdData, setFilterData] = useState([]);

    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(true);

    const [search, setSearch] = useState("");

    const [openModale, setOpenModale] = useState(false);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [data, setData] = useState({ name: "", id: "", role: "", });

    const [open, setOpen] = useState(false);
    const [customVariant, setCustomVariant] = useState("success");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState();

    const getAllbooking = async () => {
        // return await axios.get(`${localUrl}/booking/get-all`).then((response) => {
        return await axios.get(`${localUrl}/payments/get-staff`, { headers: options }).then((response) => {
            // console.log("response: ", response.data.result);
            setBooking(response.data.result)
            setFilterData(response.data.result)
            setLoading(false)
        }).catch((error) => {
            console.log("error on getAllbooking: ", error);
            setLoading(false)
            if (error?.response?.status == 401) {
                localStorage.removeItem("admin-token")
            }
        })
    }

    const handlOnSelectChange = (evt) => {
        setValSelection("")
        let dateMoment = moment(evt.$d).format('MM YYYY')
        let month = dateMoment.split(" ")[0]
        let year = dateMoment.split(" ")[1]
        setSelection({ month: month, year: year })
    }

    // this is fro seraching data of driver
    useEffect(() => {
        const searchData = booking.filter((item) =>
            item?.driver?.name.toLowerCase().includes(search.toLocaleLowerCase()) ? item : null
        );
        setFilterData(searchData);
    }, [search]);

    // This is for Designing
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        getAllbooking()
    }, [loading])


    const makePaymentNavigation = () => {
        // console.log("navigation");
        if (!selection.month && !selection.year) {
            setValSelection("Month and Year are requred!")
        } else {
            navigate(`/make-payment?month=${selection.month}&year=${selection.year}`)
        }

    }

    return (
        <Box>
            <Snackbar open={open} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "right" }} key={"top" + "right"} onClose={() => setOpen(false)}>
                <Alert onClose={() => setOpen(false)} severity={customVariant} sx={{ width: "100%" }}>
                    {error ? error : success}
                </Alert>
            </Snackbar>

            {/* <CircularProgress /> */}
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Box sx={{ width: "100%", pr: 2 }}>

                {/* <Box sx={{ width: '100%', height: 70, display: 'flex', mb: 2 }} >
                    <Box sx={{ height: '100%' }}>

                        <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ width: '100%', }}>
                            <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']} sx={{ width: '100%' }}>
                                <DatePicker label={'"Month" and "Year"'} views={['month', 'year']} sx={{ width: '100%', border: valSelection ? '1px solid red' : '', borderRadius: 1 }} onChange={handlOnSelectChange} />
                            </DemoContainer>
                        </LocalizationProvider>
                        <Typography variant='caption' color={'error'}>{valSelection}</Typography>
                    </Box>

                    <Box sx={{ flexGrow: 1 }} />

                    <Box sx={{ height: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center', }}>
                        <Button variant='contained' color='success' onClick={makePaymentNavigation}>Make Payment</Button>
                    </Box>
                </Box> */}

                <Box sx={{ width: "100%", paddingY: 2, display: 'flex' }}>
                    <Box sx={{ width: "40%" }}>
                        <TextField fullWidth label="Search..." name="search" onChange={(evt) => setSearch(evt.target.value)} value={search} placeholder="Search Driver by name" id="outlined-start-adornment"
                            InputProps={{ startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>), }}
                        />
                    </Box>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box>
                        <Link to={'/make-payment'} ><Button variant='contained' color='success' >Make Payment</Button></Link>
                    </Box>
                </Box>

                <TableContainer component={Paper}>
                    <Table >
                        <TableHead>
                            <TableRow sx={{ bgcolor: 'rgb(25, 118, 210)', color: 'white' }}>
                                <TableCell sx={{ color: 'white' }}>Name</TableCell>
                                <TableCell sx={{ color: 'white' }} >Email</TableCell>
                                <TableCell sx={{ color: 'white' }}>Mobile Number</TableCell>
                                <TableCell sx={{ color: 'white' }}>payment Amount</TableCell>
                                <TableCell sx={{ color: 'white' }}>Deduction Amount</TableCell>
                                <TableCell sx={{ color: 'white' }}>Paid Amount</TableCell>
                                <TableCell sx={{ color: 'white' }}>Due Amount</TableCell>
                                <TableCell sx={{ color: 'white' }}>Payment For Month</TableCell>
                                <TableCell sx={{ color: 'white' }}>Status</TableCell>
                                {/* <TableCell align="center"> Actions </TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filterdData && filterdData.length ? (
                                filterdData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, ind) => {
                                    return (
                                        <TableRow key={ind}>
                                            <TableCell sx={{ color: "black" }} component="th" scope="row">{item?.driver?.name}</TableCell>
                                            <TableCell sx={{ color: "black" }} >{item?.driver?.email}{" "}</TableCell>
                                            <TableCell sx={{ color: "black" }} >{item?.driver?.mobile}</TableCell>
                                            <TableCell sx={{ color: "black" }} >{item.payment_amount}</TableCell>
                                            <TableCell sx={{ color: "black" }} >{item.deduction_amount}</TableCell>
                                            <TableCell sx={{ color: "black" }} >{item.paid_amount}</TableCell>
                                            <TableCell sx={{ color: "black" }} >{item.due_amount}</TableCell>
                                            <TableCell sx={{ color: "black" }} >{item?.payment_month}/{item?.payment_year}</TableCell>
                                            <TableCell sx={{ color: "black", textTransform: 'capitalize' }} >{item.status}</TableCell>
                                            {/* <TableCell sx={{ color: "black" }} align="center">
                                            </TableCell> */}
                                        </TableRow>
                                    );
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={10} align="center">
                                        <Typography>No driver payment data found!</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <TablePagination rowsPerPageOptions={[10, 25, 100]} component="div" count={filterdData.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
                </TableContainer>
            </Box>
        </Box>
    )
}

export default Payment