import { Box, Card, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'

function BookingViewRouteDetails({ student, school }) {
    return (
        <TableContainer component={Paper} sx={{ mt: 5 }}>
            <Typography sx={{ my: 2, ml: 2 }}>Route Details</Typography>
            <Divider />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Student Area</TableCell>
                        <TableCell>Student Address</TableCell>
                        <TableCell>School Area</TableCell>
                        <TableCell>School Address</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>{student ? student?.area : ''}</TableCell>
                        <TableCell>{student ? student?.address : ''}</TableCell>
                        <TableCell>{school ? school?.area : ''}</TableCell>
                        <TableCell>{school ? school?.address : ''}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default BookingViewRouteDetails