// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";


const firebaseConfig = {
    apiKey: "AIzaSyAN6qnHDpxESq2Z3kz-bITPRZzZsztU4Vw",
    authDomain: "notification-b98ac.firebaseapp.com",
    projectId: "notification-b98ac",
    storageBucket: "notification-b98ac.appspot.com",
    messagingSenderId: "534840209350",
    appId: "1:534840209350:web:07d4803414798437a5ccc7",
    measurementId: "G-CN5DBB7C6V"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app)


export const generateToken = async () => {
    // Request permission for notifications
    const permission = await Notification.requestPermission();
    console.log("permission: ", permission);

    if (permission === 'granted') {
        // If permission is granted, get the FCM token
        const token = await getToken(messaging, { vapidKey: 'BLwTmCCJBT6yACHkyTBXixyv1XrF9sIRn5NHD485ejuwyPR2LUme0zs3Y657IdtoaWrl4ZAeHDuexdMQKnuV4lQ' });
        localStorage.setItem("fcm_token", token)
        // console.log("fcm_token: ", token);
    } else if (permission === 'denied') {
        // If permission is denied, show an alert and request permission again
        alert("Message permission is required");
        await Notification.requestPermission();
        // After re-requesting permission, you may want to recursively call generateToken again
        // generateToken(); // Uncomment this line if you want to recursively call generateToken after permission is requested again
    }
}