import { Box, Button, Divider, Modal, Paper, Typography } from "@mui/material";
import axios from "axios";
import React from "react";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
};

const basURL = process.env.REACT_APP_BASH_URL;
// const basURL = process.env.REACT_APP_BASH_IMAGE_URL

function ModaleComponent({ openModale, setOpenModale, data, setData, setSuccess, setError, setOpen, setCustomVariant, refresh, setRefresh, }) {

    const options = { Authorization: `Bearer ${localStorage.getItem("admin-token")}`, "Content-Type": "application/json", role: "admin", };

    const handleClick = async (evt) => {
        evt.preventDefault();
        if (data.role === "student") {
            return await axios.delete(`${basURL}/students/deletStudentById/${data.parentId}/${data.id}`, { headers: options }).then((response) => {
                setError("");
                setSuccess(response.data.msg);
                setCustomVariant("success");
                setOpen(true);
                setOpenModale(false);
                if (refresh) {
                    setRefresh(false);
                } else {
                    setRefresh(true);
                }
            }).catch((err) => {
                setSuccess("");
                console.log("error on deleting student: ", err);
                setError(err.response.data.msg);
                setCustomVariant("error");
                setOpen(true);
                setOpenModale(false);
            });
        } else if (data.role === "parent") {
            // console.log("data: ", data);
            return await axios.delete(`${basURL}/parents/deleteParentById/${data.parentId}`, { headers: options, }).then((response) => {
                setError("");
                setSuccess(response.data.msg);
                setCustomVariant("success");
                setOpen(true);
                setOpenModale(false);
                if (refresh) {
                    setRefresh(false);
                } else {
                    setRefresh(true);
                }
            }).catch((err) => {
                setSuccess("");
                console.log("error on deleting parent: ", err);
                setError(err.response.data.msg);
                setCustomVariant("error");
                setOpen(true);
                setOpenModale(false);
            });
        } else if (data.role === "school") {
            return await axios.delete(`${basURL}/schools/delete-school/${data.parentId}`, { headers: options, }).then((response) => {
                setError("");
                setSuccess(response.data.msg);
                setCustomVariant("success");
                setOpen(true);
                setOpenModale(false);
                setRefresh(!refresh);
            }).catch((err) => {
                setSuccess("");
                console.log("error on deleting parent: ", err);
                setError(err.response.data.msg);
                setCustomVariant("error");
                setOpen(true);
                setOpenModale(false);
            });
        } else if (data.role === "principal") {
            return await axios.delete(`${basURL}/users/deletePrincipalById/${data.parentId}`, { headers: options, }).then((response) => {
                setError("");
                setSuccess(response.data.msg);
                setCustomVariant("success");
                setOpen(true);
                setOpenModale(false);
                setRefresh(!refresh);
            }).catch((err) => {
                setSuccess("");
                console.log("error on deleting parent: ", err);
                setError(err.response.data.msg);
                setCustomVariant("error");
                setOpen(true);
                setOpenModale(false);
            });
        } else if (data.role === "teacher") {
            return await axios.delete(`${basURL}/users/delete-user/${data.parentId}`, { headers: options, }).then((response) => {
                setError("");
                setSuccess(response.data.msg);
                setCustomVariant("success");
                setOpen(true);
                setOpenModale(false);
                setRefresh(!refresh);
            }).catch((err) => {
                setSuccess("");
                console.log("error on deleting parent: ", err);
                setError(err.response.data.msg);
                setCustomVariant("error");
                setOpen(true);
                setOpenModale(false);
            });
        } else if (data.role === "driver") {
            // console.log("driver.id: ", data.parentId);
            return await axios.delete(`${basURL}/drivers/deleteDriverById/${data.id}`, { headers: options, }).then((response) => {
                setError("");
                setSuccess(response.data.msg);
                setCustomVariant("success");
                setOpen(true);
                setOpenModale(false);
                if (refresh) {
                    setRefresh(false);
                } else {
                    setRefresh(true);
                }
            }).catch((err) => {
                setSuccess("");
                console.log("error on deleting parent: ", err);
                setError(err.response.data.msg);
                setCustomVariant("error");
                setOpen(true);
                setOpenModale(false);
            });
        } else if (data.role === "route") {
            return await axios.delete(`${basURL}/routes/deleteRoute/${data.id}`, { headers: options }).then((response) => {
                setError("");
                setSuccess(response.data.msg);
                setCustomVariant("success");
                setOpen(true);
                setOpenModale(false);
                if (refresh) {
                    setRefresh(false);
                } else {
                    setRefresh(true);
                }
            }).catch((err) => {
                setSuccess("");
                console.log("error on deleting delete route: ", err);
                setError(err.response.data.msg);
                setCustomVariant("error");
                setOpen(true);
                setOpenModale(false);
            });
        } else if (data.role === "removeDriver") {
            return await axios.delete(`${basURL}/routes/removeDriverFromRoute/${data.id}`, { headers: options, }).then((response) => {
                setError("");
                setSuccess(response.data.msg);
                setCustomVariant("success");
                setOpen(true);
                setOpenModale(false);
                if (refresh) {
                    setRefresh(false);
                } else {
                    setRefresh(true);
                }
            }).catch((err) => {
                setSuccess("");
                console.log("error on deleting remove driver from route: ", err);
                setError(err.response.data.msg);
                setCustomVariant("error");
                setOpen(true);
                setOpenModale(false);
            });
        } else if (data.role === "refer") {
            return await axios.delete(`${basURL}/refers/delete/${data.id}`, { headers: options, }).then((response) => {
                setError("");
                setSuccess(response.data.msg);
                setCustomVariant("success");
                setOpen(true);
                setOpenModale(false);
                setRefresh(!refresh);
            }).catch((err) => {
                setSuccess("");
                console.log("error on deleting ref Driver: ", err);
                setError(err.response.data.msg);
                setCustomVariant("error");
                setOpen(true);
                setOpenModale(false);
            });
        } else if (data.role === "subscribe") {
            //delete-subscribe-by-admin/:amdinId/:id
            return await axios.delete(`${basURL}/subcriptions/delete-subscribe-by-admin/${data.parentId}/${data.id}`, { headers: options }).then((response) => {
                setError("");
                setSuccess(response.data.msg);
                setCustomVariant("success");
                setOpen(true);
                setOpenModale(false);
                if (refresh) {
                    setRefresh(false);
                } else {
                    setRefresh(true);
                }
            }).catch((err) => {
                setSuccess("");
                console.log("error on deleting ref Parent: ", err);
                setError(err.response.data.msg);
                setCustomVariant("error");
                setOpen(true);
                setOpenModale(false);
            });
        } else if (data.role === "area") {
            //delete-subscribe-by-admin/:amdinId/:id
            return await axios.delete(`${basURL}/area/delete-area/${data.id}`, { headers: options }).then((response) => {
                setError("");
                setSuccess(response.data.msg);
                setCustomVariant("success");
                setOpen(true);
                setOpenModale(false);
                if (refresh) {
                    setRefresh(false);
                } else {
                    setRefresh(true);
                }
            }).catch((err) => {
                setSuccess("");
                console.log("error on deleting ref Parent: ", err);
                setError(err.response.data.msg);
                setCustomVariant("error");
                setOpen(true);
                setOpenModale(false);
            });
        } else if (data.role === "vehicleType") {
            // console.log("data: ", data);
            //delete-subscribe-by-admin/:amdinId/:id
            return await axios.delete(`${basURL}/vehicleType/delete-vehicle-type/${data.parentId}`, { headers: options }).then((response) => {
                setError("");
                setSuccess(response.data.msg);
                setCustomVariant("success");
                setOpen(true);
                setOpenModale(false);
                setRefresh(!refresh)
            }).catch((err) => {
                setSuccess("");
                console.log("error on deleting ref Parent: ", err);
                setError(err.response.data.msg);
                setCustomVariant("error");
                setOpen(true);
                setOpenModale(false);
            });
        } else if (data.role === "banner") {
            return await axios.delete(`${basURL}/banners/delete/${data.parentId}`, { headers: options }).then((response) => {
                setError("");
                setSuccess(response.data.msg);
                setCustomVariant("success");
                setOpen(true);
                setOpenModale(false);
                setRefresh(!refresh)
            }).catch((err) => {
                setSuccess("");
                console.log("error on deleting ref Parent: ", err);
                setError(err.response.data.msg);
                setCustomVariant("error");
                setOpen(true);
                setOpenModale(false);
            });
        } else if (data.role === "breackDown") {
            return await axios.post(`${basURL}/break-down/approve`, { id: data.id, status: data.progress }, { headers: options }).then((response) => {
                setError("");
                setSuccess(response.data.msg);
                setCustomVariant("success");
                setOpen(true);
                setOpenModale(false);
                setRefresh(!refresh)
            }).catch((err) => {
                setSuccess("");
                console.log("error on deleting ref Parent: ", err);
                setError(err.response.data.msg);
                setCustomVariant("error");
                setOpen(true);
                setOpenModale(false);
            });
        } else if (data.role === "schoolStatus") {
            return await axios.post(`${basURL}/schools/approve`, { id: data.id, progress: data.progress }, { headers: options }).then((response) => {
                setError("");
                setSuccess(response.data.msg);
                setCustomVariant("success");
                setOpen(true);
                setOpenModale(false);
                setRefresh(!refresh)
            }).catch((err) => {
                setSuccess("");
                console.log("error on deleting ref Parent: ", err);
                setError(err.response.data.msg);
                setCustomVariant("error");
                setOpen(true);
                setOpenModale(false);
            });
        } else {
            console.log("data: ", data);
            console.log("else in modale component");
        }
    };

    return (
        <Modal keepMounted open={openModale} onClose={() => { setOpenModale(false); setData(""); }} aria-labelledby="keep-mounted-modal-title" aria-describedby="keep-mounted-modal-description">
            <Box sx={style}>
                {data.role == "breackDown" || data.role == "schoolStatus" ? <Typography id="keep-mounted-modal-title" variant="h6" component="h2" color="error">{data?.name}?</Typography> : <Typography id="keep-mounted-modal-title" variant="h6" component="h2" color="error">Are you sure you want to{" "}{data?.role === "removeDriver" ? "remove" : "delete"} {data?.name}?</Typography>}
                {/* {data.role == "schoolStatus" ? <Typography id="keep-mounted-modal-title" variant="h6" component="h2" color="error">{data?.name}?</Typography> : <Typography id="keep-mounted-modal-title" variant="h6" component="h2" color="error">Are you sure you want to{" "}{data?.role === "removeDriver" ? "remove" : "delete"} {data?.name}?</Typography>} */}
                <Divider />
                <Box sx={{ display: "flex", mt: 2 }}>
                    <Button style={{ backgroundColor: "green", color: "white" }} variant="outlined" color="success" onClick={handleClick}>Yes</Button>
                    <Box sx={{ flexGrow: 1 }} />
                    <Button style={{ backgroundColor: "red", color: "white" }} variant="outlined" color="error" onClick={() => { setOpenModale(false); setData(""); }}>Cancel</Button>
                </Box>
            </Box>
        </Modal>
    );
}

export default ModaleComponent;
