import axios from 'axios'
import React, { useEffect, useState } from 'react'
import MuiAlert from "@mui/material/Alert";
import { Avatar, Backdrop, Box, CircularProgress, Divider, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link, useNavigate, useParams } from 'react-router-dom';
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import moment from 'moment';
import { FaCalendarAlt } from "react-icons/fa";

const localUrl = process.env.REACT_APP_BASH_URL;
const imageUrl = process.env.REACT_APP_BASH_IMAGE_URL;


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function ParentView() {
    const options = { Authorization: `Bearer ${localStorage.getItem("admin-token")}`, "Content-Type": "application/json", role: "admin", };
    const navigate = useNavigate()
    const { id } = useParams()
    const [loading, setLoading] = useState(true)
    const [parent, setParent] = useState("")

    const [students, setStudents] = useState([])

    const getAllParent = async () => {
        return await axios.get(`${localUrl}/users/get-user/${id}`, { headers: options }).then((response) => {
            setParent(response.data.result)
            // console.log("response: ", response.data.result);
            setLoading(false)
        }).catch((error) => {
            console.log("error on getAllParent: ", error);
            setLoading(false)
        })
    }

    const getAllStudentByParentid = async () => {
        return await axios.get(`${localUrl}/students/get-by-parent-id/${id}`, { headers: options }).then((response) => {
            setStudents(response.data.result)
            // console.log("response: ", response.data.result);
            setLoading(false)
        }).catch((error) => {
            console.log("error on getAllStudentByParentid: ", error);
            setLoading(false)
        })
    }

    useEffect(() => {
        getAllParent()
        getAllStudentByParentid()
    }, [loading])

    return (
        <div>
            <Box sx={{ display: "flex", mb: 1 }}>
                <IconButton onClick={() => navigate(-1)}>
                    <KeyboardBackspaceIcon />
                </IconButton>

                <Typography variant="h5" component={"div"} fontWeight={700} sx={{ textDecoration: "underline", textUnderlineOffset: 3, marginLeft: 2, marginTop: 0.5, }}>Parent Details</Typography>
            </Box>

            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}><CircularProgress color="inherit" /></Backdrop>

            <Paper elevation={3} sx={{ width: '50%', mb: 1 }}>
                <Box style={{ flexDirection: "row", alignItems: "center", display: "flex", justifyContent: "space-between", }}>
                    <Typography variant="h5" sx={{ textDecoration: "underline", fontWeight: "bold", color: "#1976d2", p: 1, }}>Profile</Typography>
                    <Tooltip title="Edit Profile">
                        <Link to={`/user/update/${parent?.id}`}>
                            <IconButton style={{ color: "orange" }}>
                                <DriveFileRenameOutlineOutlinedIcon />
                            </IconButton>
                        </Link>
                    </Tooltip>
                </Box>

                <Box sx={{ width: '100%', display: 'flex' }}>
                    {/* <Box sx={{ justifyContent: "center", width: '50%' }}>
                        <Typography sx={{ mt: 1, ml: 1 }}>Profile Photo</Typography>
                        <Avatar src={`${imageUrl}/images/profile/${parent?.image}`} sx={{ width: 90, height: 90, ml: 1 }} alt={parent?.name} />
                    </Box> */}

                    <Box sx={{ padding: "10px", width: '50%' }}>
                        <Typography>Name: {parent?.name}</Typography>
                        {parent.email && <Typography>Email: {parent?.email}</Typography>}
                        <Typography>Mobile No.: {parent?.mobile}</Typography>
                        <Typography>Address: {parent?.address}</Typography>
                        <Typography>Area: {parent?.area}</Typography>
                        <Typography>Refer Code: {parent?.refer_code}</Typography>
                    </Box>
                </Box>
            </Paper>

            <TableContainer component={Paper}>
                <Typography sx={{ my: 2, ml: 2 }}>Student Lists</Typography>
                <Divider />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Profile</TableCell>
                            <TableCell align="center">Name</TableCell>
                            <TableCell align="center">Age</TableCell>
                            <TableCell align="center">Gender</TableCell>
                            <TableCell align="center">Father's Name</TableCell>
                            <TableCell align="center">Address</TableCell>
                            <TableCell align="center">Area</TableCell>
                            <TableCell align="center">Section</TableCell>
                            <TableCell align="center">Class</TableCell>
                            <TableCell align="center"> School Name </TableCell>
                            <TableCell align="center"> Register Date </TableCell>
                            <TableCell align="center"> Actions </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {students ? students.map((item, ind) => {
                            return (
                                <TableRow key={ind}>
                                    <TableCell sx={{ color: "black" }} component="th" scope="row"><Avatar src={`${imageUrl}/images/profile/${item?.photo}`} /></TableCell>
                                    <TableCell sx={{ color: "black" }} component="th" scope="row">{item?.name}</TableCell>
                                    <TableCell sx={{ color: "black" }} align="center">{item?.age}</TableCell>
                                    <TableCell sx={{ color: "black" }} align="center">{item?.gender}</TableCell>
                                    <TableCell sx={{ color: "black" }} align="center">{item?.fatherName}</TableCell>
                                    <TableCell sx={{ color: "black" }} align="center">{item?.address}</TableCell>
                                    <TableCell sx={{ color: "black" }} align="center">{item?.area}</TableCell>
                                    <TableCell sx={{ color: "black" }} align="center">{item?.section}</TableCell>
                                    <TableCell sx={{ color: "black" }} align="center">{item?.class}</TableCell>
                                    <TableCell sx={{ color: "black" }} align="center">{item?.school?.name}</TableCell>
                                    <TableCell sx={{ color: "black" }} align="center">{moment(item?.createdAt).format("LLL")}</TableCell>
                                    <TableCell sx={{ color: "black" }} align="center">
                                        <Tooltip title="View Attendance"><Link to={`/student/details/${item.id}`} style={{ textDecoration: "none" }}><IconButton color="success"><FaCalendarAlt /></IconButton>{" "}</Link>{" "}</Tooltip>
                                        <Tooltip title="View Booking"><Link to={`/student/booking/${item.id}`} style={{ textDecoration: "none" }}><IconButton color="success"><VisibilityIcon /></IconButton>{" "}</Link>{" "}</Tooltip>
                                    </TableCell>
                                </TableRow>
                            )
                        }) :
                            <TableRow>
                                <TableCell colSpan={6} align="center">
                                    <Typography>No Student data found!</Typography>
                                </TableCell>
                            </TableRow>
                        }

                    </TableBody>
                </Table>
            </TableContainer>
        </div >
    )
}

export default ParentView