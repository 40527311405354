import { Backdrop, Box, Button, CircularProgress, IconButton, InputAdornment, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import MuiAlert from "@mui/material/Alert";
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import SearchIcon from "@mui/icons-material/Search";
import axios from 'axios';
import moment from 'moment';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const bashUrl = process.env.REACT_APP_BASH_URL;

function AddressChangeRequest() {
    const options = { Authorization: `Bearer ${localStorage.getItem("admin-token")}`, "Content-Type": "application/json", role: "admin", };
    const Navigate = useNavigate();

    const [teachers, setTeachers] = useState([]);
    const [filterdData, setFilterData] = useState([]);

    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(true);

    const [search, setSearch] = useState("");

    const [openModale, setOpenModale] = useState(false);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    // const [data, setData] = useState({ name: "", id: "", role: "", });

    const [open, setOpen] = useState(false);
    const [customVariant, setCustomVariant] = useState("success");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState();



    // get-user-by-role
    const getAllUsers = async () => {
        return await axios.get(`${bashUrl}/change/get-change-request`, { headers: options }).then((response) => {
            setTeachers(response.data.result)
            setFilterData(response.data.result)
            setLoading(false)
            // console.log("response: ", response.data.result);
        }).catch((err) => {
            setLoading(false)
            console.log("error on getAllUsers: ", err);
            if (err?.response?.status == 401) {
                localStorage.removeItem("admin-token")
            }
        })
    }

    // this is fro seraching data of driver
    useEffect(() => {
        const searchData = teachers.filter((item) =>
            item?.parent?.name.toLowerCase().includes(search.toLocaleLowerCase()) ? item : null
        );
        setFilterData(searchData);
    }, [search]);

    // This is for Designing
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        getAllUsers()
    }, [loading, refresh])


    const changeApprovaleAddress = async (id, is_approved, userId) => {
        return await axios.post(`${bashUrl}/change/address-change-approvale`, { id, is_approved, userId }, { headers: options }).then((response) => {
            setError("")
            setSuccess(response.data.msg)
            setCustomVariant("success")
            setOpen(true)
            setRefresh(!refresh)
        }).catch((error) => {
            setSuccess("")
            setError(error.response.data.msg)
            setCustomVariant("error")
            setOpen(true)
        })
    }


    return (
        <Box>
            <Snackbar open={open} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "right" }} key={"top" + "right"} onClose={() => setOpen(false)}>
                <Alert onClose={() => setOpen(false)} severity={customVariant} sx={{ width: "100%" }}>
                    {error ? error : success}
                </Alert>
            </Snackbar>

            {/* <CircularProgress /> */}
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Box sx={{ width: "100%", pr: 2 }}>
                <Box sx={{ width: "100%", paddingY: 2 }}>
                    <Box sx={{ flexGrow: 1 }} />

                    <Box sx={{ width: "40%" }}>
                        <TextField fullWidth label="Search..." name="search" onChange={(evt) => setSearch(evt.target.value)} value={search} placeholder="Search..." id="outlined-start-adornment"
                            InputProps={{ startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>), }}
                        />
                    </Box>
                </Box>

                <TableContainer component={Paper}>
                    <Table /* aria-label='collapsible table' */>
                        <TableHead>
                            <TableRow>
                                {/* <TableCell>Profile</TableCell> */}
                                <TableCell >Parent Name</TableCell>
                                <TableCell align="center">Mobile Number</TableCell>
                                <TableCell align="center">Student Name</TableCell>
                                <TableCell align="center">Area</TableCell>
                                <TableCell align="center">Address</TableCell>
                                <TableCell align="center">Date</TableCell>
                                <TableCell align="center"> Actions </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filterdData && filterdData.length ? (
                                filterdData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, ind) => {
                                    return (
                                        <TableRow key={ind}>
                                            <TableCell sx={{ color: "black" }} component="th" scope="row">{item?.parent?.name}</TableCell>
                                            <TableCell sx={{ color: "black" }} align="center">{item?.parent?.mobile}</TableCell>
                                            <TableCell sx={{ color: "black" }} align="center">{item?.student?.name}</TableCell>
                                            <TableCell sx={{ color: "black" }} align="center">{item?.area}</TableCell>
                                            <TableCell sx={{ color: "black" }} align="center">{item?.sub_area}</TableCell>
                                            <TableCell sx={{ color: "black" }} align="center">{moment(item?.toDate).format('LLL')}</TableCell>
                                            <TableCell sx={{}} align="center">
                                                {item?.is_approved ? <Tooltip title='click to pending'><Button variant='contained' color='success' onClick={() => changeApprovaleAddress(item?.id, false, item?.userId)} >approved</Button></Tooltip> : <Tooltip title='click to approve'><Button variant='contained' color='error' onClick={() => changeApprovaleAddress(item?.id, true)} >Pending</Button></Tooltip>}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        <Typography>No Teacher data found!</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <TablePagination rowsPerPageOptions={[10, 25, 100]} component="div" count={filterdData?.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
                </TableContainer>
            </Box>
        </Box >
    )
}

export default AddressChangeRequest