import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react'

function BookingList({ booking }) {
    return (
        <TableContainer component={Paper}>
            <Typography variant="h6" component="div" sx={{ p: 2, fontWeight: 'bold', bgcolor: 'rgb(25, 118, 210)', color: 'white' }}>Booking</Typography>
            {/* <Divider /> */}
            <Table >
                <TableHead>
                    <TableRow sx={{ bgcolor: 'lightgrey', color: 'black' }}>
                        <TableCell sx={{ color: 'black' }} >Parent Name</TableCell>
                        <TableCell sx={{ color: 'black' }} >Student Name</TableCell>
                        <TableCell sx={{ color: 'black' }} >Driver Name</TableCell>
                        <TableCell sx={{ color: 'black' }} align="center">Booking Amount</TableCell>
                        {/* <TableCell align="center">Reject By Parent</TableCell> */}
                        {/* <TableCell align="center">Reject By Driver</TableCell> */}
                        <TableCell sx={{ color: 'black' }} align="center">Booking Date</TableCell>
                        <TableCell sx={{ color: 'black' }} align="center">Booking Status</TableCell>
                        <TableCell sx={{ color: 'black' }} align="center">Payment Status</TableCell>
                        {/* <TableCell align="center">Actions</TableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {booking ? booking?.map((item, ind) => {
                        return (
                            <TableRow key={ind}>
                                <TableCell sx={{ color: "black" }} component="th" scope="row">{item?.parent?.name}</TableCell>
                                <TableCell sx={{ color: "black" }} component="th" scope="row">{item?.student?.name}</TableCell>
                                <TableCell sx={{ color: "black" }} component="th" scope="row">{item?.driver?.name}</TableCell>
                                <TableCell sx={{ color: "black" }} align="center">{item.amount}{" "}</TableCell>
                                {/* <TableCell sx={{ color: "black" }} align="center">{item.is_reject_parent ? "Yes" : "No"}</TableCell> */}
                                {/* <TableCell sx={{ color: "black" }} align="center">{item.is_reject_driver ? "Yes" : "No"}</TableCell> */}
                                <TableCell sx={{ color: "black" }} align="center">{moment(item.createdAt).format('LL')}</TableCell>
                                <TableCell sx={{ color: "black", textTransform: 'capitalize' }} align="center">{item.status}</TableCell>
                                <TableCell sx={{ color: "black" }} align="center">{item?.payemnt_status ? "Paid" : "Not Paid"}</TableCell>
                            </TableRow>
                        );
                    }) :
                        <TableRow>
                            <TableCell colSpan={10} align="center">
                                <Typography>No Booking data found!</Typography>
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default BookingList