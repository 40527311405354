import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { Backdrop, Box, Button, CircularProgress, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography, } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
// Date picker
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { IoMdClose } from "react-icons/io";
const localUrl = process.env.REACT_APP_BASH_URL;
function DriverAttendance() {
  const options = { Authorization: `Bearer ${localStorage.getItem("admin-token")}`, "Content-Type": "multipart/form-data", role: "admin", };

  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [attendance, setAttendance] = useState([]);
  const [filterdData, setFilterData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [search, setSearch] = useState("");

  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)


  // this is fro seraching data of driver
  useEffect(() => {
    const searchData = attendance.filter((item) =>
      item.name.toLowerCase().includes(search.toLocaleLowerCase()) ? item : null
    );
    setFilterData(searchData);
  }, [search]);


  // This is for Designing
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const clearDate = async () => {
    setFromDate(null)
    setToDate(null)
    setFilterData(attendance)
  }

  const getAllAttendance = async () => {
    return await axios.get(`${localUrl}/attendance/get-all`, { headers: options }).then((response) => {
      setAttendance(response.data.result)
      setFilterData(response.data.result)
      setLoading(false)
    }).catch((err) => {
      setLoading(false)
      console.log("error on getAllAttendance: ", err);
      if (err?.response?.status == 401) {
        localStorage.removeItem("admin-token")
      }
    })
  }

  const filterDriversByDate = (startDate, toDate) => {
    if (!startDate || !toDate) {
      return attendance;
    }

    const startDateFormatted = moment(startDate.$d)
    const endDateFormatted = moment(toDate.$d)

    return attendance.filter(element => {
      let recordDate = moment(element.start);

      return recordDate.isBetween(startDateFormatted, endDateFormatted);
    });
  };

  // Call this function whenever the date range changes
  useEffect(() => {
    if (fromDate && toDate) {
      const filteredAttendance = filterDriversByDate(fromDate, toDate);
      setFilterData(filteredAttendance);
    }
  }, [fromDate, toDate, attendance]);

  useEffect(() => {
    getAllAttendance()
  }, [loading])

  return (
    <div >

      {/* <CircularProgress /> */}
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box sx={{ width: '100%', pr: 2, mt: 2 }}>
        <Grid spacing={1} container sx={{}}>
          <Grid item md={5} sm={12} xs={12}>
            <TextField fullWidth label="Search..." name="search" onChange={(evt) => setSearch(evt.target.value)} value={search} placeholder="Search Driver by name" id="outlined-start-adornment" InputProps={{ startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>), }} />
          </Grid>

          <Grid item md={3} sm={5} xs={12}>
            <Box sx={{ width: '100%' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ width: '100%' }}>
                <DatePicker label="From Date" value={fromDate} onChange={(date) => setFromDate(date)} name="startDate" sx={{ width: '100%' }} />
              </LocalizationProvider>
            </Box>
          </Grid>

          <Grid item md={3} sm={5} xs={12}>
            <Box sx={{ width: '100%', }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker label="To Date" value={toDate} onChange={(date) => setToDate(date)} name="endDate" sx={{ width: '100%' }} />
              </LocalizationProvider>
            </Box>
          </Grid>
          <Grid md={1} sm={2} sx={{ width: '100%', display: 'flex', justifyContent: 'center', }}>
            <IconButton onClick={clearDate} sx={{ width: '50px', height: '50px', alignSelf: 'center' }}> <IoMdClose size={30} /> </IconButton>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ mt: 2 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "bold", color: "#1976d2" }} align="center">Name</TableCell>
                <TableCell style={{ fontWeight: "bold", color: "#1976d2" }} align="center">Date</TableCell>
                <TableCell style={{ fontWeight: "bold", color: "#1976d2" }} align="center">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filterdData && filterdData.length ? (
                filterdData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, ind) => {
                  return (
                    <TableRow hover role="checkbox" sx={{ cursor: "pointer" }} key={ind}>
                      <TableCell align="center">{item.name}</TableCell>
                      <TableCell align="center">{moment(item.start).format("LLL")}</TableCell>
                      <TableCell align="center" sx={{ color: item.online ? "green" : "red" }}>{item.online ? "Online" : "Offline"}</TableCell>
                    </TableRow>

                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <Typography>No Driver attendance data found!</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={filterdData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>
    </div>
  );
}

export default DriverAttendance;
