import { Backdrop, Box, Card, CardActionArea, CardContent, CardMedia, CircularProgress, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CardComp from '../utils/CardComp'
import axios from 'axios';
import Report from '../utils/Report';
import { useNavigate } from 'react-router-dom';
import MuiAlert from "@mui/material/Alert";
import moment from 'moment';


const bashUrl = process.env.REACT_APP_BASH_URL;

const months = [{ name: 'January', value: 1 }, { name: 'February', value: 2 }, { name: 'March', value: 3 }, { name: 'April', value: 4 }, { name: 'May', value: 5 }, { name: 'June', value: 6 }, { name: 'July', value: 7 }, { name: 'August', value: 8 }, { name: 'September', value: 9 }, { name: 'October', value: 10 }, { name: 'November', value: 11 }, { name: 'December', value: 12 },]
const years = [{ name: 2020 }, { name: 2021 }, { name: 2022 }, { name: 2023 }, { name: 2024 }, { name: 2025 }, { name: 2026 }, { name: 2027 }, { name: 2028 }, { name: 2029 }, { name: 2030 }, { name: 2031 }, { name: 2032 }, { name: 2033 }, { name: 2034 }, { name: 2035 },]


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ReportCollection() {
    const options = { Authorization: `Bearer ${localStorage.getItem("admin-token")}`, "Content-Type": "application/json", role: "admin", };

    const [loading, setLoading] = useState(true)
    const [fine, setFine] = useState([])
    const [parent, setParent] = useState([])
    const [driver, setDriver] = useState([])

    const [month, setMonth] = useState()
    const [year, setYear] = useState()


    // start here table
    // const options = { Authorization: `Bearer ${localStorage.getItem("admin-token")}`, "Content-Type": "application/json", role: "admin", };
    const navigate = useNavigate();

    const [drivers, setDrivers] = useState([]);
    const [filterdData, setFilterData] = useState([]);

    const [refresh, setRefresh] = useState(false);

    const [search, setSearch] = useState("");


    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [data, setData] = useState({ name: "", id: "", role: "", });

    const [open, setOpen] = useState(false);
    const [customVariant, setCustomVariant] = useState("success");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState();

    const [type, setType] = useState('')


    const getCommissionReport = async () => {
        return await axios.get(`${bashUrl}/setting/get-report?role=${type}`, { headers: options }).then((response) => {
            setDrivers(response.data.result)
            setFilterData(response.data.result)
            setLoading(false)
            // console.log("response: ", response.data.result);
        }).catch((error) => {
            setLoading(false)
            console.log("error on getCommissionReport: ", error);
        })
    }

    const handlOnSelectChange = (evt) => {
        setMonth(evt.target.value)
    }

    const handlOnSelectChangeYear = (evt) => {
        setYear(evt.target.value)
    }

    // this is fro seraching data of driver
    /* useEffect(() => {
        const searchData = drivers.filter((item) =>
            item.name.toLowerCase().includes(search.toLocaleLowerCase()) ? item : null
        );
        setFilterData(searchData);
    }, [search]); */

    // This is for Designing
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const clearFilter = () => {
        setMonth("")
        setYear("")
    }

    useEffect(() => {
        getCommissionReport()
    }, [month, year, type])

    useEffect(() => {
        getCommissionReport()
    }, [loading, type])

    return (
        <Box>
            <Snackbar open={open} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "right" }} key={"top" + "right"} onClose={() => setOpen(false)}>
                <Alert onClose={() => setOpen(false)} severity={customVariant} sx={{ width: "100%" }}>
                    {error ? error : success}
                </Alert>
            </Snackbar>


            {/* <CircularProgress /> */}
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* <Box sx={{ my: 1, display: 'flex' }}>
                <Box sx={{ width: '15%' }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Month</InputLabel>
                        <Select labelId="demo-simple-select-label" id="demo-simple-select" value={month} label="Month" onChange={handlOnSelectChange}>
                            {months.map((item, ind) => {
                                return <MenuItem key={ind} value={item.value}>{item?.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{ width: '15%', ml: 1 }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Year</InputLabel>
                        <Select labelId="demo-simple-select-label" id="demo-simple-select" value={year} label="Year" onChange={handlOnSelectChangeYear}>
                            {years.map((item, ind) => {
                                return <MenuItem key={ind} value={item.name}>{item?.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Box>

                <Box>
                    <IconButton onClick={clearFilter}>clear</IconButton>
                </Box>
            </Box> */}
            <Grid spacing={2} container>
                <Grid item xs={3}>
                    <Card sx={{ maxWidth: '100%', bgcolor: type == '' ? 'lightgrey' : 'grey', color: type == '' ? 'black' : 'white', }} onClick={() => { setType(""); setLoading(true) }}>
                        <CardActionArea>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">All Collection Report</Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card sx={{ maxWidth: '100%', bgcolor: type == 'parent' ? 'lightgrey' : 'grey', color: type == 'parent' ? 'black' : 'white', }} onClick={() => { setType("parent"); setLoading(true) }}>
                        <CardActionArea>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">Collection Report Parent</Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card sx={{ maxWidth: '100%', bgcolor: type == 'teacher' ? 'lightgrey' : 'grey', color: type == 'teacher' ? 'black' : 'white', }} onClick={() => { setType("teacher"); setLoading(true) }}>
                        <CardActionArea>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">Collection Report Teacher</Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>

            </Grid>

            <TableContainer component={Paper} sx={{ mt: 2, }}>
                <Typography sx={{ textTransform: 'capitalize', mt: 1, ml: 2, fontWeight: 700 }} variant='h5' component={'div'}>{type == '' ? "all" : type}</Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Transaction No.</TableCell>
                            <TableCell align="center">Transaction Type</TableCell>
                            <TableCell align="center">Payment</TableCell>
                            <TableCell align="center">Payment Status</TableCell>
                            <TableCell align="center" >Name Parent</TableCell>
                            {type === "teacher" ? "" : <TableCell align="center" >Student Name</TableCell>}
                            <TableCell align="center" >Area</TableCell>
                            <TableCell align="center">Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filterdData && filterdData.length ? (
                            filterdData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, ind) => {
                                return (
                                    <TableRow key={ind}>
                                        <TableCell sx={{ color: "black" }} align="center">{item?.transaction_id}{" "}</TableCell>
                                        <TableCell sx={{ color: "black", textTransform: 'capitalize' }} align="center">{item?.transactionType}{" "}</TableCell>
                                        <TableCell sx={{ color: "black" }} align="center">{item?.payment}</TableCell>
                                        <TableCell sx={{ color: "black", textTransform: 'capitalize' }} align="center">{item?.paymentStatus}{" "}</TableCell>
                                        <TableCell sx={{ color: "black" }} align="center" component="th" scope="row">{item?.parent?.name}</TableCell>
                                        {type === "teacher" ? "" : <TableCell sx={{ color: "black" }} align="center" >{item?.student?.name}{" "}</TableCell>}
                                        <TableCell sx={{ color: "black" }} align="center" >{item?.parent?.area}{" "}</TableCell>
                                        <TableCell sx={{}} align="center">{moment(item?.createdAt).format('LLL')}</TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={8} align="center">
                                    <Typography>No data found!</Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <TablePagination rowsPerPageOptions={[10, 25, 100]} component="div" count={filterdData.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
            </TableContainer>
        </Box>
    )
}

export default ReportCollection