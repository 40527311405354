import { Backdrop, Box, Button, CircularProgress, Grid, IconButton, InputAdornment, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MuiAlert from '@mui/material/Alert';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios'
import moment from 'moment';
import ModaleComponent from '../popup/ModaleComponent';


// Alert notification of MUI
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const textColor = "#3684f0"
const bashUrl = process.env.REACT_APP_BASH_URL
const imageUrl = process.env.REACT_APP_BASH_IMAGE_URL

function Support() {
    const options = { 'Authorization': `Bearer ${localStorage.getItem('admin-token')}`, 'Content-Type': 'application/json', 'role': 'admin' }
    const [address, setAddress] = useState('')

    const [filterdData, setFilterData] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [refresh, setRefresh] = useState(false)
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(true)



    const [addresses, setAddresses] = useState([])

    const [open, setOpen] = useState(false);
    const [customVariant, setCustomVariant] = useState('success')
    const [error, setError] = useState('')
    const [success, setSuccess] = useState()



    const getAllArea = async () => {
        return await axios.get(`${bashUrl}/supports/get-all`, { headers: options }).then((response) => {
            setAddresses(response.data.result)
            setFilterData(response.data.result)
            setLoading(false)
            // console.log("response: ", response.data.result);
        }).catch((err) => {
            console.log("error on getAllArea: ", err);
            setLoading(false)
            if (err?.response?.status == 401) {
                localStorage.removeItem("admin-token")
            }
        })
    }



    // this is fro seraching data of driver
    useEffect(() => {
        const searchData = addresses.filter((item) => item?.user?.name.toLowerCase().includes(search.toLocaleLowerCase()) ? item : null)
        setFilterData(searchData)
    }, [search])

    // This is for pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        getAllArea()
    }, [loading, refresh])


    return (
        <div >
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading} onClick={() => setLoading(false)} >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)} anchorOrigin={{ vertical: "top", horizontal: "right" }} key={"top" + "right"}>
                <Alert onClose={() => setOpen(false)} severity={customVariant} sx={{ width: '100%' }}>
                    {error ? error : success}
                </Alert>
            </Snackbar>


            {/* <ModaleComponent openModale={openModale} setOpenModale={setOpenModale} data={data} setData={setData} setSuccess={setSuccess} setError={setError} setCustomVariant={setCustomVariant} refresh={refresh} setRefresh={setRefresh} setOpen={setOpen} /> */}


            <Box sx={{ width: '100%', paddingY: 2 }}>
                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ width: '40%' }}>
                    <TextField fullWidth label="Search..." name='serach' onChange={(e) => setSearch(e.target.value)} value={search} placeholder='Search by name' id="outlined-start-adornment" InputProps={{
                        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                    }} />
                </Box>
            </Box>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" >
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ color: "#1976d2" }} align='center'>User Name</TableCell>
                            <TableCell style={{ color: "#1976d2" }} align='center'>Subject</TableCell>
                            <TableCell style={{ color: "#1976d2" }} align='center'>Description</TableCell>
                            <TableCell style={{ color: "#1976d2" }} align='center'>Role</TableCell>
                            <TableCell style={{ color: "#1976d2" }} align='center'>Type</TableCell>
                            <TableCell style={{ color: "#1976d2" }} align='center'>Created At</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filterdData && filterdData.length ? filterdData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, ind) => {
                            return (<TableRow hover role="checkbox" sx={{ cursor: 'pointer' }} key={ind}>
                                {/* <TableCell sx={{ color: textColor }} align="left"><Avatar sx={{}} alt={item?.name} src={`${imageUrl}/images/teachers/` + item?.photo} /> </TableCell> */}
                                <TableCell sx={{ color: textColor }} component="th" scope="row" padding="none" align="center" > {item?.user?.name} </TableCell>
                                <TableCell sx={{ color: textColor }} component="th" scope="row" padding="none" align="center" > {item.subject} </TableCell>
                                <TableCell sx={{ color: textColor }} component="th" scope="row" padding="none" align="center" > {item?.description} </TableCell>
                                <TableCell sx={{ color: textColor }} component="th" scope="row" padding="none" align="center" > {item?.role == 'parent' && "Parent"}{item?.role == 'driver' && "Driver"} {item?.role == 'teacher' && "Teacher"}</TableCell>
                                <TableCell sx={{ color: textColor, textTransform: 'capitalize' }} component="th" scope="row" padding="none" align="center" > {item?.type}</TableCell>
                                <TableCell sx={{ color: textColor }} align="center">{moment(item?.createdAt).format('LL')}</TableCell>
                                {/* <TableCell sx={{ color: textColor }} align="center"><Tooltip title="Delete Area"><IconButton color='error' onClick={() => { setOpenModale(true); setData({ ...data, id: item.id, role: 'area', name: item.name }) }}> <DeleteForeverOutlinedIcon /> </IconButton></Tooltip></TableCell> */}
                            </TableRow>)
                        }) : <TableRow>
                            <TableCell colSpan={6} align='center'><Typography >No support data found!</Typography></TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
                <TablePagination rowsPerPageOptions={[10, 25, 100]} component="div" count={addresses?.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
            </TableContainer>
        </div>
    )
}

export default Support