import { Box, Button, Card, CardActions, CardContent, Container, Tooltip, Typography } from '@mui/material'
import React from 'react'
import CountUp from "react-countup";
import { Link } from 'react-router-dom';
import { LuIndianRupee } from "react-icons/lu";

function Report({ heading, count, linkTo, customBGColor, activeCount, type }) {
    return (
        <Container>
            <Card sx={{ minWidth: "100%", backgroundColor: customBGColor, padding: "20px", }}>
                <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {heading} {type == 'amount' && <LuIndianRupee size={15} style={{ marginLeft: 2 }} color='black' />} <CountUp end={count} start={0} duration={1} />

                    </Typography>
                    {/* <Box sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", }}>
                        <Tooltip title={`${Math.round((activeCount / count) * 100)}%`}></Tooltip>
                    </Box> */}
                </CardContent>
                {linkTo &&
                    <CardActions>
                        <Link to={`/${linkTo}`}>
                            <Button size="small" variant='outlined' >View More</Button>
                        </Link>
                    </CardActions>
                }
            </Card>
        </Container>
    )
}

export default Report