import { Backdrop, Box, Button, CircularProgress, Divider, IconButton, InputAdornment, Snackbar, TextField, Tooltip, } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";

import { Link } from "react-router-dom";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MuiAlert from "@mui/material/Alert";
import SearchIcon from "@mui/icons-material/Search";

// Icons
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ModaleComponent from "../popup/ModaleComponent";

const textColor = "#3684f0";
const basURL = process.env.REACT_APP_BASH_URL;
const imageUrl = process.env.REACT_APP_BASH_IMAGE_URL;

// const basURL = "http://localhost:5000/safety-buddy/api/"
// const imageUrl = "http://localhost:5000"

// Alert notification of MUI
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Row(props) {
  const { row, key, principleStatusChange, setOpenModale, setData, data } =
    props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton sx={{ color: "black" }} aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell sx={{ color: "black" }} component="th" scope="row">{row.name}{" "}</TableCell>

        <TableCell sx={{ color: "black" }} align="center">{row.email}</TableCell>

        <TableCell sx={{ color: "black" }} align="center">{row.phone}</TableCell>

        <TableCell sx={{ color: "black" }} align="center">{row.address}</TableCell>

        <TableCell sx={{ color: "black", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", }} align="center">
          {/* {row.school ? ("") : (<Link to={`/addSchoolById/${row.id}`}><Tooltip title="Add School"><IconButton color="warning">{" "}<AddOutlinedIcon />{" "}</IconButton></Tooltip></Link>)} */}

          <Link to={`updatePrincipal/${row.id}`}>
            <Tooltip title="Update Principal">
              <IconButton color="warning">
                {" "}
                <DriveFileRenameOutlineOutlinedIcon />{" "}
              </IconButton>
            </Tooltip>
          </Link>
          <Tooltip title="Delete Principal">
            <IconButton color="error" onClick={() => { setOpenModale(true); setData({ ...data, parentId: row.id, role: "principal", name: row.name, }); }}>{" "}<DeleteForeverOutlinedIcon />{" "}</IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">{" "}School Details</Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="center">Email</TableCell>
                    <TableCell align="center">Phone</TableCell>
                    <TableCell align="center">Image</TableCell>
                    <TableCell align="center">Address</TableCell>
                    <TableCell align="center" colSpan={2}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                {row.school ? (
                  <>
                    <TableBody>
                      <TableRow key={row.school?.id}>
                        <TableCell sx={{ color: textColor }} component="th" scope="row">{row.school?.name}</TableCell>

                        <TableCell align="center" sx={{ color: textColor }}>{row.school?.email}</TableCell>

                        <TableCell align="center" sx={{ color: textColor }}>{row.school?.phone}</TableCell>

                        <TableCell sx={{ color: textColor }} align="center">{" "}<img src={`${imageUrl}/images/schools/` + row.school?.image} alt={row.school?.photo} style={{ width: "150px", height: "100px" }} />{" "}</TableCell>

                        <TableCell sx={{ color: textColor }} align="center">{row.school?.address}</TableCell>

                        {/* <TableCell sx={{ color: textColor }} align="center">
                          <Link to={`updateSchool/${row?.id}/${row?.school?.id}`}>
                            <Tooltip title="Update School"><IconButton color="warning">{" "}<DriveFileRenameOutlineOutlinedIcon />{" "}</IconButton></Tooltip>{" "}
                          </Link>
                        </TableCell> */}

                        <TableCell sx={{ color: textColor }} align="center">{" "}
                          <Tooltip title="Delete School">
                            <IconButton color="error" onClick={() => { setOpenModale(true); setData({ ...data, parentId: row.school.id, role: "school", name: row?.school?.name, }); }}>{" "}<DeleteForeverOutlinedIcon />{" "}</IconButton>
                          </Tooltip>{" "}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </>
                ) : (
                  "School Data Not Found?"
                )}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function School() {
  const options = { Authorization: `Bearer ${localStorage.getItem("admin-token")}`, "Content-Type": "multipart/form-data", role: "admin", };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [principles, setPrinciples] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [modaleOpen, setModaleOpen] = useState(false);
  const [filterdData, setFilterData] = useState([]);
  const [data, setData] = useState({ parentId: "", id: "", role: "", name: "", });

  const [openApprove, setOpenApprove] = useState(false)

  // SnackBar
  const [open, setOpen] = useState(false);
  const [customVariant, setCustomVariant] = useState("success");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);

  const getAllPrinciple = async () => {
    return await axios.get(`${basURL}/schools/get-all-school`, { headers: options }).then((response) => {
      setPrinciples(response.data.result);
      setLoading(false);
      setFilterData(response.data.result);
      // console.log("response: ", response.data.result);
    }).catch((err) => {
      setLoading(false);
      setSuccess("");
      setError(err.response.data.msg);
      setCustomVariant("error");
      setOpen(true);
      console.log("error on getAllPrinciple: ", err);
      if (err?.response?.status == 401) {
        localStorage.removeItem("admin-token")
      }
    });
  };

  // this is fro seraching data of driver
  useEffect(() => {
    const searchData = principles.filter((item) =>
      item.name.toLowerCase().includes(search.toLocaleLowerCase()) ? item : null
    );
    setFilterData(searchData);
  }, [search]);

  useEffect(() => {
    getAllPrinciple();
  }, [refresh]);

  // This is for pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const principleStatusChange = async (id, status) => {
    return await axios.put(`${basURL}/schools/activatePrinciple`, { id, status }, { headers: options }).then((response) => {
      setError("");
      setCustomVariant("success");
      setSuccess(response.data.msg);
      setOpen(true);
      setLoading(false);
      setRefresh(!refresh);
    }).catch((err) => {
      setSuccess("");
      setCustomVariant("error");
      setError(err.response.data.msg);
      setOpen(true);
      setLoading(false);
      if (err?.response?.status == 401) {
        localStorage.removeItem("admin-token")
      }
    });
  };

  const handleapproveSchool = async (id, progress) => {
    setData({ name: `Are you sure to update the status to ${progress ? "active" : "in-active"}`, id: id, role: "schoolStatus", progress: progress })
    setOpenApprove(true)
  }

  // console.log("princeiples.length: ", principles.length);
  return (
    <div >
      <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)} anchorOrigin={{ vertical: "top", horizontal: "right" }} key={"top" + "right"}>
        <Alert onClose={() => setOpen(false)} severity={customVariant} sx={{ width: "100%" }}>{error ? error : success}</Alert>
      </Snackbar>

      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading} onClick={() => setLoading(false)}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <ModaleComponent openModale={openApprove} setOpenModale={setOpenApprove} data={data} setData={setData} setSuccess={setSuccess} setError={setError} setCustomVariant={setCustomVariant} refresh={refresh} setRefresh={setRefresh} setOpen={setOpen} />

      <ModaleComponent openModale={modaleOpen} setOpenModale={setModaleOpen} data={data} setData={setData} setCustomVariant={setCustomVariant} setOpen={setOpen} setSuccess={setSuccess} setError={setError} refresh={refresh} setRefresh={setRefresh} />

      <Box sx={{ pr: 2, }}>
        <Link to="/school/addSchool" style={{ textDecoration: "none", color: "white" }}><Button variant="contained">add school</Button></Link>
        <Divider sx={{ mt: 2 }} />

        <TableContainer component={Paper}>
          <Box sx={{ width: "100%", padding: 2 }}>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ width: "50%" }}>
              <TextField fullWidth label="Search..." name="serach" onChange={(e) => setSearch(e.target.value)} value={search} placeholder="Search by name" id="outlined-start-adornment" InputProps={{ startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>), }} />
            </Box>
          </Box>

          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell align="center">Phone</TableCell>
                <TableCell align="center">Image</TableCell>
                <TableCell align="center">Address</TableCell>
                <TableCell align="center">Area</TableCell>
                <TableCell align="center" colSpan={2}>Actions</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {filterdData && filterdData.length ? (
                filterdData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, ind) => {
                  return (
                    <TableRow key={ind}>
                      <TableCell sx={{ color: textColor }} component="th" scope="row">{item?.name}</TableCell>

                      <TableCell align="center" sx={{ color: textColor }}>{item?.email}</TableCell>

                      <TableCell align="center" sx={{ color: textColor }}>{item?.mobile}</TableCell>

                      <TableCell sx={{ color: textColor }} align="center">{" "}<img src={`${imageUrl}/images/schools/` + item?.image} alt={item?.photo} style={{ width: "150px", height: "100px" }} />{" "}</TableCell>

                      <TableCell sx={{ color: textColor }} align="center">{item?.address}</TableCell>
                      <TableCell sx={{ color: textColor }} align="center">{item?.area}</TableCell>

                      <TableCell sx={{ color: textColor }} align="center">
                        {item.status ? <Tooltip title="Click To In-Active"><Button variant="outlined" color="success" onClick={() => handleapproveSchool(item.id, false)} >Active</Button></Tooltip> : <Tooltip title="Click To Active"><Button variant="outlined" color="warning" onClick={() => handleapproveSchool(item.id, true)}>In-Active</Button></Tooltip>}
                        <Link to={`/school/updateSchool/${item.id}`} style={{ textDecoration: 'none' }}><Tooltip title="Update School"><IconButton color="warning"><DriveFileRenameOutlineOutlinedIcon /></IconButton></Tooltip></Link>
                        <Tooltip title="Delete School"><IconButton color="error" onClick={() => { setModaleOpen(true); setData({ ...data, parentId: item.id, role: "school", name: item?.name, }); }}><DeleteForeverOutlinedIcon />{" "}</IconButton></Tooltip>
                      </TableCell>

                      {/* <TableCell sx={{ color: textColor }} align="center">{" "}
                        {" "}
                      </TableCell> */}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <Typography>No Schools found!</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination rowsPerPageOptions={[10, 25, 100]} component="div" count={principles.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
        </TableContainer>
      </Box>
    </div>
  );
}

export default School;

/* 
{filterdData && filterdData.length ? (
                filterdData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, ind) => {
                  return (
                    <Row key={ind} row={item} principleStatusChange={principleStatusChange} setOpenModale={setModaleOpen} setData={setData} data={data} />
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <Typography>No Schools found!</Typography>
                  </TableCell>
                </TableRow>
              )}
*/