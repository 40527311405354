import { Backdrop, Box, Button, CircularProgress, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Step, StepLabel, Stepper, TextField, Typography, } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as EmailValidator from "email-validator";
import axios from "axios";
import MuiAlert from "@mui/material/Alert";
import { Snackbar } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Autocomplete from "react-google-autocomplete";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";


const basURL = process.env.REACT_APP_BASH_URL;
// const imageUrl = process.env.REACT_APP_BASH_IMAGE_URL
const locaitonApiKey = process.env.REACT_APP_GOOGLE_MAP_AP;

const imageUrl = process.env.REACT_APP_BASH_IMAGE_URL;

// Alert notification of MUI
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function AddSchool() {
  const options = { Authorization: `Bearer ${localStorage.getItem("admin-token")}`, "Content-Type": "multipart/form-data", role: "admin", }

  const navigate = useNavigate();
  const { id } = useParams();

  const [open, setOpen] = useState(false);
  const [customVariant, setCustomVariant] = useState("error");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [areas, setAreas] = useState([])


  const [imagePreview, setImagePreview] = useState('')
  const [center, setCenter] = useState({ latitude: 22.8046, longitude: 86.2029 })
  const [markerCenter, setMarkerCenter] = useState({ latitude: 22.8046, longitude: 86.2029 })

  const [address, setAddress] = useState({
    lat: '',
    lng: '',
    address: ''
  })



  const [school, setSchool] = useState({
    name: "",
    email: "",
    mobile: "",
    image: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    lat: "",
    lon: "",
    startTime: "",
    endTime: "",
    schoolUserId: id ? id : "",
    area: '',
    lat: '',
    lng: ''
  });



  const [valErrSchool, setValErrSchool] = useState({
    name: "",
    email: "",
    mobile: "",
    image: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    coordinate: "",
    startTime: "",
    endTime: "",
    area: '',
    lon: '',
    lng: ''
  });



  const handleChangeSchool = (e) => {
    setSchool({ ...school, [e.target.name]: e.target.value });

    setValErrSchool({
      name: "",
      email: "",
      mobile: "",
      image: "",
      address: "",
      city: "",
      state: "",
      pincode: "",
      coordinate: "",
      startTime: "",
      endTime: "",
      lon: '',
      lng: ''
    });
  };

  const getSingleSchool = async () => {
    return await axios.get(`${basURL}/schools/get-by-id/${id}`).then((response) => {
      setSchool(response.data.result)
      // console.log("response.data.result: ", response.data.result);
      const parsedStartTime = dayjs(response.data.result.startTime, "HH:mm:ss");
      setStartDate(parsedStartTime);
      setAddress({ address: response.data.result.address, lat: response.data.result.lat, lng: response.data.result.lng })
      setMarkerCenter({ latitude: response.data.result.lat, longitude: response.data.result.lng })

      const parsedEndTime = dayjs(response.data.result.endTime, "HH:mm:ss");
      setEndDate(parsedEndTime);
      setLoading(false)
    }).catch((error) => {
      console.log("error on getSingleSchool: ", error);
    })
  }


  const handleImageOnChage = (e) => {
    setImagePreview(URL.createObjectURL(e.target.files[0]))
    setSchool({ ...school, [e.target.name]: e.target.files[0] });
    setValErrSchool({ ...valErrSchool, address: "" });
  };




  const getArea = async () => {
    return await axios.get(`${basURL}/area/get-all-area`).then((response) => {
      setAreas(response.data.result)
      setLoading(false)
    }).catch((error) => {
      console.log("error on getting area: ", error);
      setLoading(false)
    })
  }

  const handlOnSelectChange = async (evt) => {
    setValErrSchool({ ...valErrSchool, area: '' })
    setSchool({ ...school, area: evt.target.value })
  }

  useEffect(() => {
    getArea()
    if (id) {
      getSingleSchool()
    }
  }, [loading])

  // console.log("address: ", address);


  const handleSubmitSchool = async (e) => {
    e.preventDefault();
    let isValidateForm = true;
    // Clear any previous validation errors
    setValErrSchool({});
    // console.log("sdsdsd");
    // Validate form fields

    /* if (!school.lng) {
      isValidateForm = false;
      setValErrSchool({ lng: "Longitude is required!" });
    }
    if (!school.lat) {
      isValidateForm = false;
      setValErrSchool({ lat: "Latitude is required!" });
    } */
    if (!school.area) {
      isValidateForm = false;
      setValErrSchool({ area: "Area is required!" });
    }

    if (!address.address) {
      isValidateForm = false;
      setValErrSchool({ address: "Address is required!" });
    }

    if (!school.image) {
      isValidateForm = false;
      setValErrSchool({ image: "Please choose an image!" });
    }

    if (!endDate) {
      console.log("no end date");
      isValidateForm = false;
      setValErrSchool({ endTime: "End time is required!" });
    }

    if (!startDate) {
      console.log("no start date");
      isValidateForm = false;
      setValErrSchool({ startTime: "Start time is required!" });
    }

    if (!school.pincode) {
      isValidateForm = false;
      setValErrSchool({ pincode: "Please enter school pincode!" });
    }

    if (!school.state) {
      isValidateForm = false;
      setValErrSchool({ state: "Please enter school state!" });
    }

    if (!school.city) {
      isValidateForm = false;
      setValErrSchool({ city: "Please enter school city!" });
    }

    if (school.mobile) {
      if (school.mobile.toString().length !== 10) {
        isValidateForm = false;
        setValErrSchool({ mobile: "Please enter valid mobile number" });
      }
    }

    if (school.email) {
      if (!EmailValidator.validate(school.email)) {
        isValidateForm = false;
        setValErrSchool({ email: "Please enter a valid email!" });
      }
    }

    if (!school.name) {
      isValidateForm = false;
      setValErrSchool({ name: "Please enter school name!" });
    }


    // console.log(isValidateForm);
    if (isValidateForm) {
      // If form validation passes, proceed with form submission
      setLoading(true);


      const startDateTemp = new Date(startDate);
      // Create a new Date object with the provided date string

      // Extract hours, minutes, and seconds from the Date object
      const hours = ("0" + startDateTemp.getHours()).slice(-2); // Add leading zero and take last two characters
      const minutes = ("0" + startDateTemp.getMinutes()).slice(-2); // Add leading zero and take last two characters
      const seconds = ("0" + startDateTemp.getSeconds()).slice(-2); // Add leading zero and take last two characters

      // Concatenate hours, minutes, and seconds into a time string
      const formattedStartTime = `${hours}:${minutes}:${seconds}`;
      const endDateTemp = new Date(endDate);
      // Create a new Date object with the provided date string

      // Extract hours, minutes, and seconds from the Date object
      const endhours = ("0" + endDateTemp.getHours()).slice(-2); // Add leading zero and take last two characters
      const endminutes = ("0" + endDateTemp.getMinutes()).slice(-2); // Add leading zero and take last two characters
      const endseconds = ("0" + endDateTemp.getSeconds()).slice(-2); // Add leading zero and take last two characters

      // Concatenate hours, minutes, and seconds into a time string
      const formattedEndTime = `${endhours}:${endminutes}:${endseconds}`;

      const formData = new FormData();
      formData.append("name", school.name);
      formData.append("email", school.email);
      formData.append("mobile", school.mobile);
      formData.append("address", address.address);
      formData.append("city", school.city);
      formData.append("state", school.state);
      formData.append("image", school.image);
      formData.append("pincode", school.pincode);
      formData.append("schoolUserId", school.schoolUserId);
      formData.append("startTime", formattedStartTime);
      formData.append("endTime", formattedEndTime);
      formData.append("area", school.area);
      formData.append("lat", address.lat);
      formData.append("lng", address.lng);
      if (id) {
        formData.append("id", id)
      }
      if (id) {
        return await axios.post(`${basURL}/schools/update-by-id`, formData, { headers: options }).then((response) => {
          setLoading(false);
          setError("");
          setSuccess(response.data.msg);
          setCustomVariant("success");
          setOpen(true);
          navigate("/school");
        }).catch((err) => {
          setLoading(false);
          setSuccess("");
          console.log("Error on handleSubmitSchool: ", err);

          const errorMessage = err.response?.data?.err || err.response?.data?.msg || "An error occurred";
          setError(errorMessage);
          setCustomVariant("error");
          setOpen(true);
        })
      }
      return await axios.post(`${basURL}/schools/add-school`, formData, { headers: options }).then((response) => {
        setLoading(false);
        setError("");
        setSuccess(response.data.msg);
        setCustomVariant("success");
        setOpen(true);
        navigate("/school");
      }).catch((err) => {
        setLoading(false);
        setSuccess("");
        console.log("Error on handleSubmitSchool: ", err);

        const errorMessage = err.response?.data?.err || err.response?.data?.msg || "An error occurred";
        setError(errorMessage);
        setCustomVariant("error");
        setOpen(true);
      })
    }
  };

  // console.log("startDate: ", startDate);
  // console.log("endDate:", endDate);

  // console.log("address: ", address);


  return (
    <div >
      <Box sx={{ width: "100%" }}>
        <Box sx={{ width: "100%", display: "inline-flex" }}>
          <IconButton onClick={() => navigate(-1)}>{" "}<KeyboardBackspaceIcon />{" "}</IconButton>
        </Box>

        <Snackbar open={open} autoHideDuration={6000} onClose={() => { setOpen(false); }} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
          <Alert onClose={() => setOpen(false)} severity={customVariant} sx={{ width: "100%" }}>{error ? error : success}</Alert>
        </Snackbar>

        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading} onClick={() => setLoading(false)}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <Typography variant="h5" component={"div"}>{id ? "Update" : "Register"} School</Typography>
        <Box component="form" noValidate sx={{ mt: 3, pr: 2, mb: 3 }}>
          <Grid spacing={2} container>
            <Grid item xs={12} sm={6} md={6}>
              <TextField type="text" name="name" id="outlined-basic" label="School Name" variant="outlined" error={valErrSchool.name ? true : false} helperText={valErrSchool.name ? valErrSchool.name : ""} value={school.name} onChange={handleChangeSchool} fullWidth required />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <TextField type="email" name="email" id="outlined-basic" label="School Email" variant="outlined" error={valErrSchool.email ? true : false} helperText={valErrSchool.email ? valErrSchool.email : ""} value={school.email} onChange={handleChangeSchool} fullWidth />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <TextField type="number" name="mobile" id="outlined-basic" label="School Mobile Number" variant="outlined" error={valErrSchool.mobile ? true : false} helperText={valErrSchool.mobile ? valErrSchool.mobile : ""} value={school.mobile} onChange={handleChangeSchool} fullWidth />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <TextField type="text" name="city" id="outlined-basic" label="City" variant="outlined" error={valErrSchool.city ? true : false} helperText={valErrSchool.city ? valErrSchool.city : ""} value={school.city} onChange={handleChangeSchool} fullWidth required />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <TextField type="text" name="state" id="outlined-basic" label="State" variant="outlined" error={valErrSchool.state ? true : false} helperText={valErrSchool.state ? valErrSchool.state : ""} value={school.state} onChange={handleChangeSchool} fullWidth required />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <TextField type="number" name="pincode" id="outlined-basic" label="Pincode" variant="outlined" error={valErrSchool.pincode ? true : false} helperText={valErrSchool.pincode ? valErrSchool.pincode : ""} value={school.pincode} onChange={handleChangeSchool} fullWidth required />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs} >
                <TimePicker label="Start TIme" sx={{ width: '100%' }} value={startDate} onChange={(date) => setStartDate(date)} name="startTime" fullWidth timeIntervals={15} timeCaption="Time" dateFormat="h:mm aa" error={valErrSchool.startTime ? true : false} helperText={valErrSchool.startTime ? valErrSchool.startTime : ''} />
              </LocalizationProvider>
              {valErrSchool.startTime ? (<Typography color={"error"} variant="caption">{valErrSchool.startTime}</Typography>) : ("")}
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker label="End Time" sx={{ width: '100%' }} value={endDate} onChange={(date) => setEndDate(date)} name="endTIme" fullWidth timeIntervals={15} timeCaption="Time" dateFormat="h:mm aa" error={valErrSchool.endTime ? true : false} helperText={valErrSchool.endTime ? valErrSchool.endTime : ''} />
              </LocalizationProvider>
              {valErrSchool.endTime ? (<Typography color={"error"} variant="caption">{valErrSchool.endTime}</Typography>) : ("")}
            </Grid>



            <Grid item xs={12} sm={6} md={6}>
              <Autocomplete
                apiKey={locaitonApiKey}
                style={{ width: '100%', height: 55, fontSize: 17, paddingLeft: 10, borderRadius: 5, border: valErrSchool.coordinate ? `1px solid red` : '' }}
                onPlaceSelected={(place) => {
                  const lat = place.geometry.location.lat();
                  const lng = place.geometry.location.lng();
                  setMarkerCenter({ latitude: lat, longitude: lng });
                  setAddress({ ...address, address: place?.formatted_address, lat: lat, lng: lng });
                }}
                options={{ types: ["address"], componentRestrictions: { country: "in" }, }}
                defaultValue={address.address}
              />
              {valErrSchool.address ? (<Typography color={"error"}>{valErrSchool.address}</Typography>) : ("")}
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Area</InputLabel>
                <Select labelId="demo-simple-select-label" id="demo-simple-select" value={school.area} label="Area" onChange={handlOnSelectChange}>
                  {areas.map((item, ind) => {
                    return <MenuItem key={ind} value={item.name}>{item?.name}</MenuItem>
                  })}
                </Select>
              </FormControl>
              {valErrSchool.area ? (<Typography color={"error"} variant="caption">{valErrSchool.area}</Typography>) : ("")}
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <Grid spacing={2} container>
                <Grid item xs={6}>
                  <Box sx={{ width: '100%', height: 100, border: '1px dashed black' }}>
                    <img src={imagePreview ? imagePreview : `${imageUrl}/images/schools/` + school?.image} alt="" style={{ width: '100%', height: '100%' }} />
                  </Box>
                  {valErrSchool.image ? (<Typography color={"error"} variant="caption">{valErrSchool.image}</Typography>) : ("")}
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ width: '100%', height: 100, display: 'flex', alignItems: 'center', }}>
                    <label htmlFor="schoolImage" style={{ width: '70%', height: '45px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#1976d2', color: 'white', borderRadius: '5px', cursor: 'pointer' }}>Upload Image</label>
                    <TextField sx={{ display: 'none' }} type="file" name="image" id="schoolImage" variant="outlined" onChange={handleImageOnChage} error={valErrSchool.image ? true : false} helperText={valErrSchool.image ? valErrSchool.image : ""} fullWidth required />
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Box component={Paper} sx={{ width: "100%", height: 500 }}>
                <APIProvider apiKey={locaitonApiKey}>
                  <Map initialOptions={{ center: { lat: address?.lat ? address?.lat : center.latitude, lng: address?.lng ? address.lng : center.longitude }, zoom: 14 }} defaultZoom={14}
                    mapContainerStyle={{ width: "100%", height: "100%" }}
                    onClick={(e) => { const lat = e.latLng.lat(); const lng = e.latLng.lng(); setMarkerCenter({ latitude: lat, longitude: lng }); setAddress({ ...address, lat, lng }); }}
                  >
                    {/* <Marker position={{ lat: markerCenter.latitude, lng: markerCenter.longitude }} draggable={true} onDragEnd={(e) => console.log("e: ", e.latLng.lat())} /> */}
                    <Marker position={{ lat: markerCenter.latitude, lng: markerCenter.longitude }} draggable={true} onDragEnd={(e) => { setAddress({ ...address, lat: e.latLng.lat(), lng: e.latLng.lng() }); setMarkerCenter({ latitude: e.latLng.lat(), longitude: e.latLng.lng() }) }} />
                  </Map>
                </APIProvider>
              </Box>
              <Typography variant="body" component={"div"} color={"error"}>{valErrSchool.coordinate ? valErrSchool.coordinate : ""}</Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Button type="submit" variant="contained" onClick={handleSubmitSchool} sx={{ width: "50%", marginLeft: "25%", marginRight: "25%" }}>submit</Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div >
  );
}

export default AddSchool;
