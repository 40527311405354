import React, { useEffect, useState } from 'react'
import axios from "axios";
import MuiAlert from "@mui/material/Alert";
import { Box, Card, CardContent, Grid, Snackbar, Typography } from '@mui/material';
import CardComp from '../utils/CardComp';
import BreakDownlList from '../components/BreakDownlList';
import BookingList from '../components/BookingList';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const bashUrl = process.env.REACT_APP_BASH_URL;

function Home({ setMenu, showError }) {

    const options = { Authorization: `Bearer ${localStorage.getItem("admin-token")}`, "Content-Type": "application/json", role: "admin", };

    const [schools, setSchools] = useState([]);
    const [students, setStudents] = useState([]);

    const [drivers, setDrivers] = useState([])
    const [parents, setParents] = useState([])
    const [teachers, setTeachers] = useState([])

    const [breakDown, setBreakDown] = useState([])
    const [booking, setBooking] = useState([])


    const [open, setOpen] = useState(false);
    const [customVariant, setCustomVariant] = useState("success");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState();
    const [loading, setLoading] = useState(false);



    const getAllUsers = async () => {
        return await axios.get(`${bashUrl}/users/get-all-user`, { headers: options }).then((response) => {
            setDrivers(response.data.result.filter(item => item.role === "driver"))
            setParents(response.data.result.filter(item => item.role === "parent"))
            setTeachers(response.data.result.filter(item => item.role === "teacher"))
            setTeachers(response.data.result.filter(item => item.role === "teacher"))
            // setSchools(response.data.result.filter(item => item.role === "principle"))
            setLoading(false)
            // console.log("response: ", response.data.result);
        }).catch((err) => {
            setLoading(false)
            console.log("error on getAllUsers: ", err);
            if (err?.response?.status == 401) {
                localStorage.removeItem("admin-token")
            }
        })
    }

    const getAllSchool = async () => {
        return await axios.get(`${bashUrl}/schools/get-all-school`).then((response) => {
            setSchools(response.data.result)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            console.log("error on getAllSchool: ", error);
            if (error?.response?.status == 401) {
                localStorage.removeItem("admin-token")

            }
        })
    }

    const getLastBreakDown = async () => {
        return await axios.get(`${bashUrl}/break-down/get-last-all`).then((response) => {
            setBreakDown(response.data.result)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            console.log("error on getLastBreakDown: ", error);
        })
    }

    const getLastBooking = async () => {
        return await axios.get(`${bashUrl}/booking/get-last-all`).then((response) => {
            setBooking(response.data.result)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            console.log("error on getLastBreakDown: ", error);
        })
    }

    useEffect(() => {
        getAllUsers()
        getAllSchool()
        getLastBreakDown()
        getLastBooking()
    }, [loading]);


    useEffect(() => {
        getLastBreakDown()
        getLastBooking()
    }, [loading, showError]);

    // console.log("breakDown: ", breakDown);



    return (
        <Box>
            <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>{" "}<Alert onClose={() => setOpen(false)} severity={customVariant} sx={{ width: "100%" }}>{" "}{error ? error : success}{" "}</Alert>{" "}</Snackbar>

            <Grid spacing={2} container>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <CardComp heading={"Number of Parents"} count={parents.length} linkTo={"parent"} customBGColor={"lightgrey"} activeCount={parents.filter((item) => item.status === "active").length} setMenu={setMenu} />
                </Grid>

                {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                    <CardComp heading={"Number of Parents"} count={5} linkTo={"parent"} customBGColor={"lightgrey"} activeCount={10} />
                </Grid> */}

                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <CardComp heading={"Number of Driver"} count={drivers.length} linkTo={"driver"} customBGColor={"lightblue"} activeCount={drivers.filter((item) => item.status === "active").length} setMenu={setMenu} />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <CardComp heading={"Number of Teacher"} count={teachers.length} linkTo={"teacher"} customBGColor={"lightgreen"} activeCount={teachers.filter((item) => item.status === "active").length} setMenu={setMenu} />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <CardComp heading={"Number of School"} count={schools?.length} linkTo={"school"} customBGColor={"lightgrey"} activeCount={10} setMenu={setMenu} />
                </Grid>
            </Grid>

            <Grid spacing={2} container sx={{ mt: 1, px: 2 }}>
                <Grid item xs={12}>
                    {/* <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', height: 40, my: 1, boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', }}>
                        <Typography variant='h6' component={'div'} >Break Down</Typography>
                    </Box> */}
                    {/* <Card sx={{ width: '100%', height: 40, display: 'flex', alignItems: 'center', bgcolor: 'lightgrey' }}>
                        <CardContent >
                            <Typography variant='h6' component={'div'} >Break Down</Typography>
                        </CardContent>
                    </Card> */}
                    <BreakDownlList breakDown={breakDown} />
                </Grid>

                <Grid item xs={12}>
                    <BookingList booking={booking} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default Home
// {/* <CardComp heading={"Number of Student"} count={students.length} linkTo={"parent"} customBGColor={"lightgrey"} activeCount={students.length} /> */}
