import { Backdrop, Box, Button, CircularProgress, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Snackbar, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MuiAlert from '@mui/material/Alert';
import ImagesCard from '../comman/ImagesCard';
import { useNavigate, useParams } from 'react-router-dom'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import axios from 'axios';


const basURL = process.env.REACT_APP_BASH_URL
const imageUrl = process.env.REACT_APP_BASH_IMAGE_URL

// Alert notification of MUI
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function UpdateTeacher() {
    const options = { 'Authorization': `Bearer ${localStorage.getItem('admin-token')}`, 'Content-Type': 'multipart/form-data', 'role': 'admin' }

    const { id } = useParams()
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);
    const [customVariant, setCustomVariant] = useState('error')
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')
    const [imagePreview, setImagePreview] = useState('')
    const [loading, setLoading] = useState(true)

    const [areas, setAreas] = useState([])

    const [teacher, setTeacher] = useState({
        name: '',
        email: '',
        photo: '',
        address: '',
        mobile: '',
        area: ''
    })
    const [valError, setValerror] = useState({
        name: '',
        email: '',
        photo: '',
        address: '',
        mobile: '',
        area: ''
    })


    const getSingleTeacher = async () => {
        return await axios.get(`${basURL}/users/get-user/${id}`, { headers: options }).then((response) => {
            setTeacher(response.data.result)
            setLoading(false)
        }).catch((err) => {
            setLoading(false)
            console.log("error on getSingleTeacher: ", err);
        })
    }

    const getArea = async () => {
        return await axios.get(`${basURL}/area/get-all-area`).then((response) => {
            setAreas(response.data.result)
            setLoading(false)
        }).catch((error) => {
            console.log("error on getting area: ", error);
            setLoading(false)
        })
    }

    const handleOnChange = (evt) => {
        setTeacher({ ...teacher, [evt.target.name]: evt.target.value })
        setValerror({ name: '', age: '', gender: '', photo: '', identityCard: '', marriedStatus: '', address: '', mobile: '' })
    }

    const handleImageOnChage = (e) => {
        // console.log("image: ", e.target.files[0]);
        setImagePreview(URL.createObjectURL(e.target.files[0]))
        setTeacher({ ...teacher, photo: e.target.files[0] })
        setValerror({ ...valError, photo: '' })
    }

    const handlOnSelectChange = async (evt) => {
        setValerror('')
        setTeacher({ ...teacher, area: evt.target.value })
    }

    useEffect(() => {
        getSingleTeacher()
        getArea()
    }, [loading])

    const updateTeacherProfile = async (evt) => {
        evt.preventDefault()
        let isValidate = true
        /* if (!teacher.photo) {
            isValidate = false
            setValerror({ ...valError, photo: 'Image is required!' })
        } */
        if (!teacher.name) {
            isValidate = false
            setValerror({ ...valError, name: 'Name is required!' })
        }

        /* if (!teacher.email) {
            isValidate = false
            setValerror({ ...valError, email: 'Email is required!' })
        } */
        if (!teacher.address) {
            isValidate = false
            setValerror({ ...valError, address: 'Address is required!' })
        }
        if (!teacher.mobile) {
            isValidate = false
            setValerror({ ...valError, mobile: 'Mobile number is required!' })
        }
        if (teacher.mobile.toString().length !== 10) {
            isValidate = false
            setValerror({ ...valError, mobile: 'Invalide mobile number!' })
        }

        if (isValidate) {
            const formData = new FormData()
            formData.append("name", teacher.name)
            formData.append("email", teacher.email)
            if (teacher.photo) {
                formData.append("photo", teacher.photo)
            }
            formData.append("address", teacher.address)
            formData.append("mobile", teacher.mobile)
            formData.append("area", teacher.area)
            formData.append("id", id)
            setLoading(true)
            return await axios.post(`${basURL}/users/update-user`, formData, { headers: options }).then((response) => {
                setSuccess(response.data.msg)
                setCustomVariant('success')
                setOpen(true)
                setLoading(false)
                navigate(-1)
            }).catch((err) => {
                console.log("error on updateTeacherProfile: ", err);
                setError(err.response.data.msg)
                setCustomVariant('error')
                setLoading(false)
                setOpen(true)
            })
        }
    }

    // console.log("image: ", `${imageUrl}/images/profile/${teacher?.image}`);

    return (
        <Box>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading} onClick={() => setLoading(false)} >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar open={open} autoHideDuration={6000} onClose={() => { setOpen(false) }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} key={'top' + 'right'}>
                <Alert onClose={() => setOpen(false)} severity={customVariant} sx={{ width: '100%' }}>
                    {error ? error : success}
                </Alert>
            </Snackbar>

            <Box sx={{ display: 'flex' }}>
                <IconButton onClick={() => navigate(-1)} > <KeyboardBackspaceIcon /> </IconButton><Typography variant='h5' component={'div'} fontWeight={700} sx={{ textDecoration: 'underline', textUnderlineOffset: 3, marginLeft: 2, marginTop: 0.5 }} >Update User{/* Update Teacher */}</Typography>
            </Box>

            <Box component={'form'} noValidate onSubmit={updateTeacherProfile} sx={{ paddingRight: 2, marginTop: 2 }}>
                <Grid spacing={2} container>
                    {teacher.role == 'parent' ? '' :
                        <Grid item xs={12}>
                            <Box sx={{ width: '100%', alignItems: 'center', justifyContent: 'center', mb: 3, mt: -3 }}>
                                <center> <ImagesCard style={{}} imageCardUrl={teacher?.image ? `${imageUrl}/images/profile/${teacher?.image}` : './user.png'} imageName={teacher.name} valError={valError.photo} imagePreview={imagePreview} handleImageOnChage={handleImageOnChage} Label={'Profile'} idForLabel={"school-image"} /> </center>
                            </Box>
                        </Grid>
                    }

                    <Grid item xs={12} sm={6} md={6}>
                        <TextField type='text' name='name' id="outlined-basic" label="Name" variant="outlined" error={valError.name ? true : false} helperText={valError.name ? valError.name : ''} value={teacher.name} onChange={handleOnChange} fullWidth required />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                        <TextField type='number' name='mobile' id="outlined-basic" label="Mobile Number" variant="outlined" error={valError.mobile ? true : false} helperText={valError.mobile ? valError.mobile : ''} value={teacher.mobile} onChange={handleOnChange} fullWidth required />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                        <TextField type='text' name='address' id="outlined-basic" label="Address" variant="outlined" error={valError.address ? true : false} helperText={valError.address ? valError.address : ''} value={teacher.address} onChange={handleOnChange} fullWidth required />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                        <TextField type='eamil' name='email' id="outlined-basic" label="Email" variant="outlined" error={valError.email ? true : false} helperText={valError.email ? valError.email : ''} value={teacher.email} onChange={handleOnChange} fullWidth />
                    </Grid>


                    <Grid item xs={12} sm={6} md={6}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Area</InputLabel>
                            <Select labelId="demo-simple-select-label" id="demo-simple-select" value={teacher.area} label="Area" onChange={handlOnSelectChange}>
                                {areas.map((item, ind) => {
                                    return <MenuItem key={ind} value={item.name}>{item?.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        {/* <TextField type="text" name="area" id="outlined-basic" label="Area" variant="outlined" error={valErrSchool.area ? true : false} helperText={valErrSchool.area ? valErrSchool.area : ""} value={school.area} onChange={handleChangeSchool} fullWidth required /> */}
                        {valError.area ? (<Typography color={"error"} variant="caption">{valError.area}</Typography>) : ("")}
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                        <Button type='submit' variant='contained' sx={{ width: '30%', marginLeft: '35%', marginRight: '35%' }}>Update</Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default UpdateTeacher