import React, { useEffect, useState } from 'react'
import { Backdrop, Box, Button, CircularProgress, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Paper, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import SearchIcon from "@mui/icons-material/Search";
import moment from 'moment';
import MuiAlert from '@mui/material/Alert';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ReciveCash from '../popup/ReciveCash';

// Alert notification of MUI
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



const localUrl = process.env.REACT_APP_BASH_URL;
function PassengerPayemnt({ admin }) {
    const options = { Authorization: `Bearer ${localStorage.getItem("admin-token")}`, "Content-Type": "application/json", role: "admin", };
    const navigate = useNavigate();

    const [booking, setBooking] = useState([])

    const [selection, setSelection] = useState({
        month: null,
        year: null
    })
    const [valSelection, setValSelection] = useState('')

    const [filterdData, setFilterData] = useState([]);

    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(true);

    const [search, setSearch] = useState("");

    const [openDailog, setOpenDailog] = useState(false);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [open, setOpen] = useState(false);
    const [customVariant, setCustomVariant] = useState("success");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState();

    const [data, setData] = useState('')

    const getAllbooking = async () => {
        // return await axios.get(`${localUrl}/booking/get-all`).then((response) => {
        return await axios.get(`${localUrl}/passenger/get-all`, { headers: options }).then((response) => {
            // console.log("response: ", response.data.result);
            setBooking(response.data.result)
            setFilterData(response.data.result)
            setLoading(false)
        }).catch((error) => {
            console.log("error on getAllbooking: ", error);
            setLoading(false)
            if (error?.response?.status == 401) {
                localStorage.removeItem("admin-token")
            }
        })
    }

    // console.log("admin.area: ", admin?.area);

    const getAllBookingByArea = async () => {
        return await axios.get(`${localUrl}/passenger/get-by-area?area=${admin?.area}`, { headers: options }).then((response) => {
            // console.log("response: ", response.data.result);
            let result = response.data.result
            setBooking(response.data.result)
            setFilterData(response.data.result)
            setLoading(false)
        }).catch((error) => {
            console.log("error on getAllbooking: ", error);
            setLoading(false)
            if (error?.response?.status == 401) {
                localStorage.removeItem("admin-token")
            }
        })
    }


    // this is fro seraching data of driver
    useEffect(() => {
        const searchData = booking?.filter((item) =>
            item?.student?.name.toLowerCase().includes(search.toLocaleLowerCase()) ? item : null
        );
        setFilterData(searchData);
    }, [search]);

    // This is for Designing
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };



    useEffect(() => {
        if (admin?.role == 'agent') {
            getAllBookingByArea()
        } else {
            getAllbooking()
        }
    }, [loading, refresh])


    return (
        <Box>
            <Snackbar open={open} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "right" }} key={"top" + "right"} onClose={() => setOpen(false)}>
                <Alert onClose={() => setOpen(false)} severity={customVariant} sx={{ width: "100%" }}>
                    {error ? error : success}
                </Alert>
            </Snackbar>

            {/* <CircularProgress /> */}
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Box sx={{ width: "100%", pr: 2 }}>

                {/* <Box sx={{ width: '100%', height: 70, display: 'flex', mb: 2 }} >
                    <Box sx={{ height: '100%' }}>

                        <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ width: '100%', }}>
                            <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']} sx={{ width: '100%' }}>
                                <DatePicker label={'"Month" and "Year"'} views={['month', 'year']} sx={{ width: '100%', border: valSelection ? '1px solid red' : '', borderRadius: 1 }} onChange={handlOnSelectChange} />
                            </DemoContainer>
                        </LocalizationProvider>
                        <Typography variant='caption' color={'error'}>{valSelection}</Typography>
                    </Box>

                    <Box sx={{ flexGrow: 1 }} />

                    <Box sx={{ height: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center', }}>
                        <Button variant='contained' color='success' onClick={makePaymentNavigation}>Make Payment</Button>
                    </Box>
                </Box> */}

                <Box sx={{ width: "100%", paddingY: 2, display: 'flex' }}>
                    <Box sx={{ width: "40%" }}>
                        <TextField fullWidth label="Search..." name="search" onChange={(evt) => setSearch(evt.target.value)} value={search} placeholder="Search student by name" id="outlined-start-adornment"
                            InputProps={{ startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>), }}
                        />
                    </Box>
                    <Box sx={{ flexGrow: 1 }} />
                    {admin.role == 'admin' && <>
                        <Box>
                            <Link to={'/passenger/make-payment'} ><Button variant='contained' color='success' >Make Payment</Button></Link>
                        </Box>
                    </>}
                </Box>

                <ReciveCash openDailog={openDailog} setOpenDailog={setOpenDailog} loading={loading} setLoading={setLoading} setSuccess={setSuccess} setError={setError} setCustomVariant={setCustomVariant} setOpen={setOpen} data={data} setData={setData} admin={admin ? admin : ''} refresh={refresh} setRefresh={setRefresh} />

                <TableContainer component={Paper}>
                    <Table >
                        <TableHead>
                            <TableRow>
                                <TableCell >Name</TableCell>
                                <TableCell >School Name</TableCell>
                                <TableCell align='center'>Payment Amount</TableCell>
                                <TableCell align='center'>Fine Amount</TableCell>
                                <TableCell align='center'>Paid Amount</TableCell>
                                <TableCell align='center'>Due Amount</TableCell>
                                <TableCell align='center'>Month / Year</TableCell>
                                <TableCell align="center">Payment Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filterdData && filterdData.length ? (
                                filterdData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, ind) => {
                                    return (
                                        <TableRow key={ind}>
                                            <TableCell sx={{ color: "black", }} component="th" scope="row">{item?.student?.name}</TableCell>
                                            <TableCell sx={{ color: "black", }} component="th" scope="row">{item?.student?.school?.name}</TableCell>
                                            <TableCell sx={{ color: "black" }} align='center' >{item?.payment_amount}{" "}</TableCell>
                                            <TableCell sx={{ color: "black" }} align='center' >{item?.fine_amount}</TableCell>
                                            <TableCell sx={{ color: "black" }} align='center' >
                                                {item?.paid_amount}
                                                {/* <TextField
                                                    type="number"
                                                    name="fine_amount"
                                                    id="deduction-amount"
                                                    label="Fine Amount"
                                                    variant="standard"
                                                    value={parseFloat(item?.paid_amount)}
                                                    onChange={(e) => handleInputChange(ind, "fine_amount", parseFloat(e.target.value))}
                                                    fullWidth
                                                    required
                                                /> */}
                                            </TableCell>
                                            <TableCell sx={{ color: "black" }} align='center' >{item?.due_amount ? item?.due_amount : 0}</TableCell>
                                            <TableCell sx={{ color: "black", textTransform: 'capitalize' }} align='center' >{item?.month}/{item?.year}</TableCell>
                                            <TableCell sx={{ color: "black", textTransform: 'capitalize' }} align='center' >{item?.payment_status ? "Paid" : <Button variant='contained' onClick={() => { setOpenDailog(true); setData(item) }}>Cash Recived</Button>}</TableCell>
                                        </TableRow>
                                    );
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={10} align="center">
                                        <Typography>No passenger payment data found!</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <TablePagination rowsPerPageOptions={[10, 25, 100]} component="div" count={filterdData.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
                </TableContainer>
            </Box>
        </Box>
    )
}

export default PassengerPayemnt